<template>
    <v-dialog
        v-model="show"
        persistent
        width="900px"
      >
        <v-card style="border-radius:0;">
          <v-card-title>
            <v-spacer/>
            <v-icon 
              color="red"
              @click.native="$emit('close-dialog-show-fullimage')" 
              style="cursor:pointer;">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-img
              :src="image"
              lazy-src="/imgs/placeholder.png"
              width="100%"
              contain
              style="max-height:80vh;"
            >
            </v-img>
          </v-card-text>
        </v-card>
      </v-dialog>
</template>

<script>
export default {
    props:["show","image"]
}
</script>