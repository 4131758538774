<template>
  <v-navigation-drawer
    app
    v-model="drawer"
    :mini-variant.sync="mini"
    :mini-variant-width="$route.name == 'Login' ? 0 : 70"
    style="padding-top:62px;"
    @transitionend="collapseSubItems"
  >
    <v-list v-if="userId">
      <v-list-item-group>
        <template v-for="(item, index) in items">
          <v-list-item
            v-if="!item.subs && matchRole(item.allows)"
            :key="item.title + 1000"
            link
            :to="item.to"
          >
            <v-list-item-icon>
              <v-icon :color="item.color" style="font-size:30px;">{{
                item.icon
              }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            v-if="item.subs && matchRole(item.allows)"
            :key="index"
            v-model="item.active"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon :color="item.color" style="font-size:30px;">{{
                  item.icon
                }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <template v-for="(subMenu, subIndex) in item.subs">
              <!-- Menu level 2 -->
              <v-list-item :key="100 + subIndex" dense :to="subMenu.to">
                <v-list-item-icon v-if="1 == 1" style="width:30px;">
                  <!-- <v-icon :color="subMenu.color" style="font-size:30px;"></v-icon> -->
                </v-list-item-icon>

                <v-list-item-content style="">
                  <v-list-item-title link style="font-size:14px;">
                    <!-- <v-icon :color="subMenu.color" style="">{{ subMenu.icon }}</v-icon> -->
                    {{ subMenu.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
        </template>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    value: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  watch: {
    $route(val){
      // console.log("wath-nav-$route:",window.location.pathname, val)
      if (["/forget-password", "/login"].includes(val.fullPath)) {
        this.drawer = false
      }
      else{
        if(this.$vuetify.breakpoint.smAndDown){
          this.drawer = false
        } else {
          this.drawer = true
        }
        
      }
    }
  },
  computed: {
    ...mapGetters({
      userId: "userId",
      token: "token",
    }),
  },
  data() {
    return {
      drawer: false,
      mini: false,
      items: require("@/mkdata/navigator").navigators,
    };
  },
  mounted() {
  },
  methods: {
    doLogout() {
      this.$store.dispatch("logout").then(() => {
        location.reload();
      });
    },
    matchRole (allowRoles) {
      console.log('Call check matchRole')
      if(!allowRoles) return true
      if (this.$root.roles.length<=0) return false
      let allow = false

      this.$root.roles.forEach(role => {
        if(allowRoles.includes(role)){
          allow = true
        }
      })
      return allow
    },
    toggleDisplay() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.drawer = true;
        this.mini = !this.mini;
      } else {
        this.mini = false;
        this.drawer = !this.drawer;
      }
    },
    collapseSubItems() {
      this.items.map((item) => (item.active = false));
    },
  },
  created() {
    const vm = this;
    vm.$eventBus.$on("toggleNavigationBar", this.toggleDisplay);
  },
  destroyed() {
    const vm = this;
    vm.$eventBus.$off("toggleNavigationBar", this.toggleDisplay);
  },
};
</script>

<style>
.active-tile {
}
.active-child {
  font-weight: 600;
  color: "#FFFFFF44";
}
.cursor-pointer {
  cursor: pointer;
}
</style>
