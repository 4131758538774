<template>
  <v-app>
    <!--  #EF5350 #264B77 #c13589 -->
    <v-app-bar
      app
      clipped-left
      color="teal"
      dark
      dense
      height="58"
      style="z-index:10;"
    >
      <v-btn v-if="token" 
        color="white"
        icon @click="toggleNavigationBar">
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <div class="d-flex align-center" style="width:180px;">
        <v-img
          style="cursor:pointer;max-width:96px;background-color:#FFFFFF;border-radius:2px;"
          @click="goto('Home')"
          src="/imgs/mcvlogo128.png"
          class="my-3"
          contain
          height="44"
        />
      </div>
      <v-spacer></v-spacer>

      <v-menu
        v-if="token"
        offset-y
        origin="center center"
        :nudge-bottom="10"
        transition="scale-transition"
        class="mr-3"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            text
            slot="activator"
            color="white"
            v-on="on"
            style="margin:0px;text-transform:none;font-size:14px;width:100px;min-width:30px;padding:0px;"
            :ripple="false"
          >
            <div
              style="overflow: hidden;margin:0px;padding:0px;width:100%;height:auto;"
            >
              <span v-if="getFullName"> {{ getFullName }}</span>
              <span v-else> <v-icon>mdi-account-circle</v-icon></span>
            </div>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                class="cursor-pointer"
                @click="goto('UserProfile')"
              >
                <v-icon class="mr-2">mdi-account-circle</v-icon>Hồ sơ cá nhân
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="role == 'ninAnd033' || role == 'ASnind010'">
            <v-list-item-content>
              <v-list-item-title
                class="cursor-pointer"
                @click="goto('SetupUsers')"
              >
                <v-icon class="mr-2">mdi-account-settings</v-icon>Setup Users
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="cursor-pointer" @click="logoutUser">
                <v-icon class="mr-2">mdi-logout</v-icon>Đăng xuất
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn
        v-if="1 == 2 && !token"
        text
        style="text-transform: capitalize;"
        @click="goto('Login')"
      >
        <v-icon>mdi-login</v-icon> Đăng nhập
      </v-btn>
      <!-- <point-setter v-model='setterDialog' :point="setterPoint"/> -->
    </v-app-bar>

    <v-content style="background-color:#F6F6F6;">
      <keep-alive>
        <router-view />
      </keep-alive>
    </v-content>
    <navigation
      v-model="drawer"
      v-if="1 == 1 || $vuetify.breakpoint.smAndDown"
    />
  </v-app>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
const moment = require("moment");
export default {
  name: "App",
  components: {
    // LoginDialog,
  },
  computed: {
    ...mapGetters([
      "mute",
      "token",
      "getFullName",
      "role",
      "userId",
      "notiBlackList",
    ]),
    muted() {
      return this.$store.state.mute == "1";
    },
    hasErrorNoti() {
      if (this.notifications && this.notifications.length > 0) {
        return (
          this.notifications.filter(
            (n) =>
              n.alarmLevel == "error" && !this.notiBlackList.includes(n.desc)
          ).length > 0
        );
      }
      return false;
    },
    hasWarningNoti() {
      if (this.notifications && this.notifications.length > 0) {
        return (
          this.notifications.filter(
            (n) =>
              n.alarmLevel == "warning" && !this.notiBlackList.includes(n.desc)
          ).length > 0
        );
      }
      return false;
    },
    hasDangerNoti() {
      if (this.notifications && this.notifications.length > 0) {
        return (
          this.notifications.filter(
            (n) =>
              n.alarmLevel == "danger" && !this.notiBlackList.includes(n.desc)
          ).length > 0
        );
      }
      return false;
    },
  },
  watch: {
    userId () {
      this.fetchUserRoles()
    }
  },
  /*
    watch: {
      muted: function() {console.log('muted changed ' + this.muted)}
    },
    */
  sockets: {
    connect: function() {
      console.log("IO Connected");
      this.$socket.emit("registerIsHmi", {});
      this.$socket.emit("registerIsZKListener", {});
      this.$socket.emit("resgisterIsMcvKhoListener", {});
    },
    currentAlarm: function(alarms) {
      this.updateAllNotification(alarms);
    },
    // customEmit: function (data) {
    //     console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
    // }
  },
  data: () => ({
    // isLogin: false,
    drawer: false,
    notifications: [],
    setterDialog: false,
    setterPoint: null,
    kindOfAlarms: {
      normal: { color: "blue", icon: "mdi-information-outline" },
      warning: { color: "orange", icon: "mdi-alert-outline" },
      error: { color: "red", icon: "mdi-close-octagon-outline" },
      danger: { color: "purple", icon: "mdi-close-octagon-outline" },
    },
  }),
  methods: {
    ...mapMutations(["setIsLogin"]),
    toggleMute() {
      if (this.mute && this.mute == "1") {
        this.$store.dispatch("unmuteAlarm");
      } else {
        this.$store.dispatch("muteAlarm");
      }
    },
    showPointControl(point) {
      this.setterPoint = point;
      this.setterDialog = true;
    },
    toggleNavigationBar() {
      this.$eventBus.$emit("toggleNavigationBar");
    },
    goto(routeName) {
      this.$router.push({ name: routeName });
    },
    logoutUser() {
      try {
        // Set Log
        this.$store.dispatch("setLogUser", {
          desc: `User '${this.getFullName}' logout`,
          userId: this.userId,
          manName: this.getFullName,
          tags: "logout",
          date: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
        });
      } catch (err) {
        console.log("Logout Err:", err);
      }

      this.$store.dispatch("logout").then(() => {
        this.goto("Login");
      });
    },
    updateAllNotification(alarms) {
      this.notifications = [];
      if (alarms) {
        // console.log(alarms)
        alarms.forEach((alarm) => {
          this.notifications.push({
            icon: this.kindOfAlarms[alarm.alarmLevel].icon,
            desc: alarm.desc,
            content:
              alarm.desc +
              (alarm.value != true && alarm.value != false
                ? ", " + alarm.value + " " + alarm.unit
                : ""),
            color: this.kindOfAlarms[alarm.alarmLevel].color,
            alarmLevel: alarm.alarmLevel,
            isActive: true,
          });
        });
      }
    },
    addNotiBlock(name) {
      this.$store
        .dispatch("addNotiToBlackList", {
          name: name,
        })
        .then((res) => {
          console.log(res.status);
        });
    },
    removeNotiBlock(name) {
      this.$store
        .dispatch("removeNotiFromBlackList", {
          name: name,
        })
        .then((res) => {
          console.log(res.status);
        });
    },
    async fetchUserRoles () {
      if(!this.userId) return
      try {
        console.log('Fetch user roles for ' + this.userId)
        let res = await this.axios.get(this.$root.apiUser + 
          `/roles?customer_id=${this.$root.apiUserCustomId}&enabled=1&userIds=${this.userId}`)
        // console.log('All Roles')
        // console.log(res.data)
        if (res.data.status == "OK"){
          let roles = res.data.content
          this.$root.roles = roles.map(role => role.code)
        }
        console.log(this.$root.roles)
      } catch (err) {
        console.log(err)
      }
    }
  },
  async created() {
    // const vm = this;
    // vm.$eventBus.$on("showPointSetter", this.showPointControl);
      await this.fetchUserRoles()
  },
  destroyed() {
    // const vm = this;
    // vm.$eventBus.$off("showPointSetter", this.showPointControl);
  },
};
</script>

<style>
.activeNotify {
  font-size: 13px !important;
  font-weight: 700 !important;
}
.seenNotify {
  font-size: 12px !important;
  font-weight: 400 !important;
}
</style>
