<template>
   <div>
      <v-data-table
         :headers="displayHeader"
         :items="users"
         :page.sync="page"
         :items-per-page="19"
         hide-default-footer dense
         class="elevation-1"
         @page-count="pageCount = $event"
         :mobile-breakpoint="0"
      >
         <template v-slot:top>
            <v-card flat class="pa-0" v-if="users.length>0">
              <v-card-title class="pt-2 pb-1">
                <v-btn dark color="green" 
                  @click="exportExcel">
                  <v-icon class="mr-2">
                    mdi-download
                  </v-icon>
                  Xuất
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
         </template>
      </v-data-table>

      <div v-if="pageCount>1"
         class="text-center pt-2 px-8">
         <v-pagination
            v-model="page"
            :length="pageCount"
            ></v-pagination>
      </div>
   </div>
</template>

<script>
let XLSX = require("xlsx");
export default {
   props: ['index', 'users', 'dateRange'],
   watch: {
      index: function () {
         this.buildReport()
      },
   },
   data () {
      return {
         pageCount: 0,
         page: 1,
         header: [
            {  text: 'Mã NV', 
               value: 'mcvId',
               width: '70px'
            },
            {
               text: 'Nhân viên',
               align: 'start',
               sortable: false,
               value: 'fullname',
               // value: 'user_code',
               width: '220px',
            },
            {
               text: 'Ngày công',
               align: 'start',
               sortable: false,
               value: 'numOfDate',
               // value: 'user_code',
               width: '90px',
            },
            {
               text: 'Số ph đi trễ',
               align: 'start',
               sortable: false,
               value: 'diTre',
               // value: 'user_code',
               width: '90px',
            },
            {
               text: 'Số ph về sớm',
               align: 'start',
               sortable: false,
               value: 'veSom',
               // value: 'user_code',
               width: '90px',
            },
            {
               text: 'Số ph OT',
               align: 'start',
               sortable: false,
               value: 'OT',
               // value: 'user_code',
               width: '90px',
            }
         ],
         displayHeader: [],
         numOfDate: 0
      }
   },
   methods: {
      buildReport: function () {
         this.displayHeader = []
         this.displayHeader = this.displayHeader.concat(this.header)
         let mStartDate = this.$moment(this.dateRange.start)
         let mEndDate = this.$moment(this.dateRange.end)
         let numOfDate = mEndDate.diff(mStartDate, 'days') + 1
         this.numOfDate = numOfDate

         let dates = []
         this.users.forEach(user => {
            user.numOfDate = 0
            user.veSom = 0
            user.diTre = 0
            user.OT = 0

         })
         for(let i = 0; i < numOfDate; i++){
            let mDate = this.$moment(mStartDate.toDate()).add(i, 'days')
            mDate.locale('vn')
            let dateStr = mDate.format("ddd DD/MM")
            let datStrFull = mDate.format("DD/MM/YYYY")
            let dateCode = mDate.format("DDMM")
            dates.push(mDate)
            this.displayHeader.push({
               text: dateStr,
               align: 'start',
               sortable: false,
               value: dateCode+'s',
               width: '120px',
            })

            this.users.forEach(user => {
               if(user.attds.length>0){
                  if(user[dateCode] == undefined){
                     user[dateCode] = {
                        min: null,
                        max: null,
                        diTre: 0,
                        veSom: 0,
                        OT: 0,
                        ngayCong: 0
                     }
                  }
                  let matchAttds = user.attds.filter(attd => attd.attTime.endsWith(datStrFull))
                  if(matchAttds && matchAttds.length>0){
                     
                     matchAttds.forEach(attd => {
                        if(user[dateCode].min){
                           if(this.$moment(attd.attTime, "HH:mm:ss DD/MM/YYYY") < this.$moment(user[dateCode].min, "HH:mm:ss DD/MM/YYYY")){
                              user[dateCode].min = attd.attTime
                           }
                        }
                        if(!user[dateCode].min) user[dateCode].min = attd.attTime

                        if(user[dateCode].max){
                           if(this.$moment(user[dateCode].max, "HH:mm:ss DD/MM/YYYY") < this.$moment(attd.attTime, "HH:mm:ss DD/MM/YYYY")){
                              user[dateCode].max = attd.attTime
                           }
                        }

                        if(!user[dateCode].max) user[dateCode].max = attd.attTime
                     })

                     

                     let calculateRet = this.calculateNgayCongForOneDate(user[dateCode].min, user[dateCode].max)

                     user[dateCode].ngayCong = calculateRet.ngayCong
                     user[dateCode].diTre = calculateRet.diTre
                     user[dateCode].veSom = calculateRet.veSom
                     user[dateCode].OT = calculateRet.OT
                     user.numOfDate += calculateRet.ngayCong
                     user.diTre += calculateRet.diTre
                     user.veSom += calculateRet.veSom
                     user.OT += calculateRet.OT

                     if(user[dateCode].min==null){
                        user[dateCode+'s'] = ""
                     } else {
                        if(user[dateCode].min != user[dateCode].max){
                           user[dateCode+'s'] = user[dateCode].min.substring(0,5) + " - " + user[dateCode].max.substring(0,5) + ` (${calculateRet.ngayCong})`
                           if(calculateRet.diTre>0) {
                              user[dateCode+'s'] += `, đi trễ ${calculateRet.diTre}ph`
                           }
                           if(calculateRet.veSom>0) {
                              user[dateCode+'s'] += `, về sớm ${calculateRet.veSom}ph`
                           }
                           if(calculateRet.OT>0) {
                              user[dateCode+'s'] += `, OT ${calculateRet.OT}ph`
                           }
                        } else {
                           user[dateCode+'s'] = user[dateCode].min.substring(0,5) + ` (0)`
                           if(calculateRet.OT>0) {
                              user[dateCode+'s'] += `, OT ${calculateRet.OT}ph`
                           }
                        }
                     }

                  }
               }
            })

         }

         // console.log(dates)
         this.$forceUpdate()
      },
      calculateNgayCongForOneDate(firstSeen, lastSeen) {
         let ret = {
            ngayCong: 1,
            diTre: 0,
            veSom: 0,
            OT: 0
         }
         if(!firstSeen || !lastSeen || firstSeen==lastSeen) {
            ret = {
               ngayCong: 0,
               diTre: 0,
               veSom: 0,
               OT: 0
            }
         }

         
         let weekDay = this.$moment(firstSeen, "HH:mm:ss DD/MM/YYYY").weekday()
         // console.log(firstSeen + "  weekday " + weekDay)

         let firstSeenHour = this.$moment(firstSeen, "HH:mm:ss DD/MM/YYYY").get('hour')
         let firstSeenMin = this.$moment(firstSeen, "HH:mm:ss DD/MM/YYYY").get('minute')

         let lastSeenHour = this.$moment(lastSeen, "HH:mm:ss DD/MM/YYYY").get('hour')
         let lastSeenMin = this.$moment(lastSeen, "HH:mm:ss DD/MM/YYYY").get('minute')

         let totalMinuteSeen = (lastSeenHour*60+lastSeenMin) - (firstSeenHour*60+firstSeenMin)
         let totalHourSeen = Math.round(totalMinuteSeen/6)/10

         if(totalHourSeen<5) {
            ret.ngayCong = 0.5
         }

         if (totalHourSeen<2) {
            ret.ngayCong = 0
         }

         if (ret.ngayCong==1 && weekDay!=6) {
            let soPhutDiTre = (firstSeenHour*60+firstSeenMin) - 9*60
            if (soPhutDiTre>15 && soPhutDiTre <= 4*60) {
               ret.diTre += soPhutDiTre
            }

            let soPhutVeSom = 18*60 - (lastSeenHour*60+lastSeenMin)
            if (soPhutVeSom>15 && soPhutVeSom <= 4*60) {
               ret.veSom += soPhutVeSom
            }
         }

         let soPhutDiSom = 9*60 - (firstSeenHour*60+firstSeenMin)
         if(soPhutDiSom > 60) {
            ret.OT += soPhutDiSom
         }

         let soPhutVeTre =  (lastSeenHour*60+lastSeenMin) - 18*60 
         if (soPhutVeTre > 60) {
            ret.OT += soPhutVeTre
         }

         if(weekDay==6 && ret.ngayCong==1){
            ret.OT = totalMinuteSeen - 4*60
            if(ret.OT<60) ret.OT=0
         }


         if(weekDay==6 && ret.ngayCong==0.5){
            ret.ngayCong = 1
         }

         if(weekDay==0){
            ret.diTre = 0
            ret.veSom = 0
            ret.OT = 0
            if (ret.ngayCong>0){
               ret.ngayCong = ret.ngayCong*3
            }
         }

         
         return ret
      },
      exportExcel() {
         let wb = XLSX.utils.book_new();
         let data = [];
         let header = this.displayHeader.map((theader) => theader.text);
         let valueKey = this.displayHeader.map((theader) => theader.value);
         this.users.forEach((user) => {
            let returnRow = {};
            for(let i=0; i<header.length; i++) {
               returnRow[header[i]] = user[valueKey[i]]
            }

            data.push(returnRow)
         });

         let dataWS = XLSX.utils.json_to_sheet(data, {
            header: header,
         });
         let wscols = [{ wpx: 60 }, { wpx: 200 }, {wpx: 90} ];
         header.forEach((f, index) => {
            if (index > 2) wscols.push({ wpx: 120 });
         });
         dataWS["!cols"] = wscols;
         XLSX.utils.book_append_sheet(wb, dataWS, "Report");
         const fileName = this.genExcelFileName();
         XLSX.writeFile(wb, fileName);
      },
      genExcelFileName() {
            let filename = "Cham cong";
            filename =
            filename +
            " - " +
            this.$moment(this.dateRange.start).format("DD-MM-YYYY") +
            " den " +
            this.$moment(this.dateRange.end).format("DD-MM-YYYY") +
            ".xlsx";
            return filename;
      },
   }
}
</script>

<style>
   
</style>