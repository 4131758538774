<template>
   <div>
      <v-data-table
         :headers="header"
         :items="attds"
         :page.sync="page"
         :items-per-page="19"
         hide-default-footer dense
         class="elevation-1"
         @page-count="pageCount = $event"
         :mobile-breakpoint="0"
      ></v-data-table>
      <div v-if="pageCount>1"
         class="text-center pt-2 px-8">
         <v-pagination
            v-model="page"
            :length="pageCount"
            ></v-pagination>
      </div>
   </div>
</template>

<script>
export default {
   props: ['attds'],
   data () {
      return {
         pageCount: 0,
         page: 1,
         header: [
            {  text: 'Mã NV', 
               value: 'devUserId',
               width: '90px'
            },
            {
               text: 'Nhân viên',
               align: 'start',
               sortable: false,
               value: 'name',
               // value: 'user_code',
               width: '280px',
            },
            { text: 'Ngày giờ', value: 'attTime', width: '180px', },
            { text: 'Khu vực', value: 'floorName' },
            { text: 'Cửa', value: 'doorName' },
         ],
      }
   }
}
</script>

<style>
   
</style>