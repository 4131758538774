<template>
   <v-menu
      v-model='menuDatePicker'
      :close-on-click="false"
      :close-on-content-click="false"> 
      <template v-slot:activator="{ on, attrs }">
         <div 
            v-bind="attrs"
            class="py-2 px-4 text-center"
            style="background-color:white;border-radius:6px;font-size:16px;min-width:180px;"
            v-on="on">
            <div style="display:inline-block;"
                  class="mr-4">
               <v-icon style="font-size:24px;" color="#555555">
                  mdi-calendar-check
               </v-icon>
            </div>
            <div style="display:inline-block;">
               {{$moment(range.start).format('DD/MM/YYYY')}}
               <template v-if="$moment(range.start).format('DDMMYY')!=$moment(range.end).format('DDMMYY')">
                  - {{$moment(range.end).format('DD/MM/YYYY')}}
               </template>
            </div>
         </div>
      </template>
      <v-card flat>
         <v-card-text class="pa-2">
            <div>
               <div style="display:inline-block;vertical-align:top;">
                  <vc-date-picker 
                     v-model="range" 
                     ref='datepicker'
                     is-range :model-config="modelConfig"/>
               </div>
               <div v-if="$vuetify.breakpoint.mdAndUp"
                  class="py-0 pl-2 pr-0"
                  style="display:inline-block;vertical-align:top;">
                  <v-list dense style="min-width:140px;font-size:16px;">
                     <v-list-item link style="min-width:100px;"
                        @click="pickdate('today')">
                        <v-list-item-content>
                           Hôm nay
                        </v-list-item-content>
                     </v-list-item>
                     <v-list-item link style="min-width:100px;"
                        @click="pickdate('yesterday')">
                        <v-list-item-content>
                           Hôm qua
                        </v-list-item-content>
                     </v-list-item>
                     <v-list-item link style="min-width:100px;"
                        @click="pickdate('this-week')">
                        <v-list-item-content>
                           Tuần này
                        </v-list-item-content>
                     </v-list-item>
                     <v-list-item link style="min-width:100px;"
                        @click="pickdate('last-week')">
                        <v-list-item-content>
                           Tuần trước
                        </v-list-item-content>
                     </v-list-item>
                     <v-list-item link style="min-width:100px;"
                        @click="pickdate('this-month')">
                        <v-list-item-content>
                           Tháng này
                        </v-list-item-content>
                     </v-list-item>
                     <v-list-item link style="min-width:100px;"
                        @click="pickdate('last-month')">
                        <v-list-item-content>
                           Tháng trước
                        </v-list-item-content>
                     </v-list-item>
                     <v-list-item link style="min-width:100px;"
                        @click="pickdate('last-7-days')">
                        <v-list-item-content>
                           7 ngày qua
                        </v-list-item-content>
                     </v-list-item>
                     <v-list-item link style="min-width:100px;"
                        @click="pickdate('last-30-days')">
                        <v-list-item-content>
                           30 ngày qua
                        </v-list-item-content>
                     </v-list-item>
                  </v-list>
               </div>
            </div>
         </v-card-text>
         <v-card-actions>
            <v-spacer/>
            <v-btn text color="black" @click="notifyDateChange">
               OK
            </v-btn>
         </v-card-actions>
      </v-card>
   </v-menu>
</template>

<script>
export default {
   props: ['initDateMode'],
   data () {
      return {
         menuDatePicker: false,
         rangeMode: 'day',
         range: {
            start: this.$moment().startOf('day').toDate(),
            end: this.$moment().endOf('day').toDate(),
         },
         modelConfig: {
            start: {
               timeAdjust: '00:00:00',
            },
            end: {
               timeAdjust: '23:59:59',
            },
         }
      }
   },
   methods: {
      pickdate (type) {
         const datepicker = this.$refs.datepicker;
         
         if (type == 'today') {
            this.rangeMode = 'day'
            this.range = {
               start: this.$moment().startOf('day').toDate(),
               end: this.$moment().endOf('day').toDate()
            }
         }
         if (type == 'yesterday') {
            this.rangeMode = 'day'
            this.range = {
               start: this.$moment().add(-1, 'd').startOf('day').toDate(),
               end: this.$moment().add(-1, 'd').endOf('day').toDate()
            }
         }
         if (type == 'this-week') {
            this.rangeMode = 'week'
            this.range = {
               start: this.$moment().startOf('week').toDate(),
               end: this.$moment().endOf('week').toDate()
            }
         }

         if (type == 'last-week') {
            this.rangeMode = 'week'
            this.range = {
               start: this.$moment().startOf('week').add(-7, 'd').toDate(),
               end: this.$moment().endOf('week').add(-7, 'd').toDate()
            }
         }

         if (type == 'this-month') {
            this.rangeMode = 'month'
            this.range = {
               start: this.$moment().startOf('month').toDate(),
               end: this.$moment().endOf('month').toDate()
            }
         }

         if (type == 'last-month') {
            this.rangeMode = 'month'
            this.range = {
               start: this.$moment().add(-1,'months').startOf('month').toDate(),
               end: this.$moment().add(-1,'months').endOf('month').toDate()
            }
            
         }

         if (type == 'last-7-days') {
            this.rangeMode = '7day'
            this.range = {
               start: this.$moment().add(-7,'d').toDate(),
               end: this.$moment().toDate()
            }
         }

         if (type == 'last-30-days') {
            this.rangeMode = '30days'
            this.range = {
               start: this.$moment().add(-30,'d').toDate(),
               end: this.$moment().toDate()
            }
         }

         this.menuDatePicker = false
         datepicker.move(this.range.start)

         this.notifyDateChange()
      },
      notifyDateChange () {
         this.menuDatePicker = false
         this.$emit('dateChange', {
            rangeMode: this.rangeMode,
            range: this.range
         })
      }
   },
   mounted() {
      if(this.initDateMode){
         if(this.initDateMode=='thisWeek'){
            this.range = {
               start: this.$moment().startOf("week").toDate(),
               end: this.$moment().endOf("week").toDate(),
            }
         }
      }
      this.notifyDateChange()
   },
}
</script>