<template>
  <v-container fluid >
    <v-row class="pa-0">
      <v-col class="pa-0" cols="12">
        <v-autocomplete
          v-model="resultUsers"
          :items="users"
          outlined
          filled
          chips
          color="black"
          background-color="white"
          label="Chọn khách mời"
          item-text="fullname"
          item-value="_id"
          multiple
          hide-details
          @change="$emit('inviter-done', resultUsers)"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              color="grey lighten-2"
              dense
              close
              @click="data.select"
              @click:close="removeInviter(data.item)"
            >
              {{ data.item.fullname }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <template v-if="(typeof data.item !== 'object')">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-avatar>
                <img :src="!data.item.avatar? '/imgs/user-placehold.jpg': data.item.avatar">
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.fullname"></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      users: [],
      resultUsers: [],
      value: null,
    };
  },
  computed: {
    usersAfterFilter() {
      let result = [];
      result = this.users
      return result;
    },
  },
  methods: {
    removeInviter (item) {
      const index = this.resultUsers.indexOf(item._id)
      if (index >= 0) this.resultUsers.splice(index, 1)
    },
    convertMaNVToCompanyId(code) {
      if (code && code.length > 0) {
        let sCode = code.replace("NV", "");
        if (sCode.includes("T")) return sCode;
        else return Number(sCode);
      }
      return -1;
    },
    processAfterFetch() {
      let self = this;
      self.users.forEach((u) => {
        u.mcvId = this.convertMaNVToCompanyId(u.user_code);
      });

      self.users = self.users.sort(function(a, b) {
        return Number(a.mcvId) - Number(b.mcvId);
      });

      this.users.forEach((u) => (u.match = false));
    },
    fetchUsers() {
      let self = this;
      let pathFetchDataUsers =
        self.$root.apiUser + "/users/list/" + self.$root.apiUserCustomId;
      // let pathFetchDataDepartments =
      // self.$root.apiUser + "/depts/list/" + self.$root.apiUserCustomId;

      Promise.all([
        self.axios.get(pathFetchDataUsers),
        // self.axios.get(pathFetchDataDepartments),
      ])
        .then((responseArray) => {
          if (responseArray[0].data.status == "OK") {
            self.users = responseArray[0].data.content.items;
            // console.log("Users:", self.users);
          }

          //   this.$forceUpdate();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          self.processAfterFetch();
        });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.fetchUsers();
    });
  },
};
</script>
