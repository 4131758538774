<template>
  <div class="props-drop-down pa-0" style="line-height:0;width:100%;">
    <v-menu
      v-model="showMenu"
      bottom
      :close-on-click="false"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          dark
          color="black"
          :block="fullWidth"
          outlined
          v-bind="attrs"
          v-on="on"
          :height="large ? 40 : 32"
          :disabled="disabled || items.length<=0"
          class="text-none px-2 mx-0"
          style="border-radius:6px;max-width:100%;"
          :style="{
            'width': $vuetify.breakpoint.mdAndUp?'100%':'100%',
            'background-color': disabled ? '#F6F6F6 !important' : '#FFFFFF',
            color: disabled ? '#AAAAAA !important' : '',
          }"
        >
          <div style="width:calc(100% - 18px);" class="text-left">
            <span>
              {{ display_text }}
            </span>
          </div>

          <div style="width:18px;" class="text-right">
            <v-icon
              style="font-size:18px;"
              class="mr-2"
              :style="{
                color: disabled ? '#AAAAAA !important' : '',
              }"
            >
              mdi-chevron-down
            </v-icon>
          </div>
        </v-btn>
      </template>

      <v-card class="pa-0">
        <v-card-title v-if="multiple"
          class="py-1" style="border-bottom: 1px solid #CCCCCC;">
          <v-btn text color="green" class="text-none" @click="closeMenu">
            Xong
          </v-btn>
          <v-spacer />

          <v-btn
            text
            color="#333333"
            class="text-none"
            @click="setAllItemSelected"
          >
            Select All
          </v-btn>
          <v-btn
            text
            color="#333333"
            class="text-none"
            @click="selected_items = []"
          >
            Select None
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0 pb-2 pr-1" 
          style="height:500px;max-height:70vh;">
          <div class="py-1 pt-2 px-2  d-flex" style="width:100%;">
            <v-spacer></v-spacer>
            <v-text-field
              class="pr-0"
              v-model="searchString"
              append-icon="mdi-magnify"
              label="Search"
              outlined
              single-line
              hide-details
              clearable
              dense
              style="width:300px;max-width:100%;"
            ></v-text-field>
          </div>
          <div style="overflow-y: scroll;height:calc(100% - 50px);">
            <v-data-table
              v-model="selected_items"
              :headers="headers"
              :items="displayItems"
              :items-per-page="50"
              :search="searchString"
              :loading="loading"
              loading-text="Loading... Please wait"
              dense
              style="padding:6px;max-width:420px;"
              :show-select="multiple"
              hide-default-header
              hide-default-footer
              :page.sync="depsPage"
              @page-count="pageCount = $event"
              :mobile-breakpoint="0"
              :single-select="!multiple"
              @click:row="onRowClick"
            >
            </v-data-table>
          </div>
          <div v-if="pageCount > 1" class="text-center pt-2">
            <v-pagination v-model="depsPage" :length="pageCount"></v-pagination>
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    text: { default: "", type: String },
    multiple: { default: false, type: Boolean },
    disabled: { default: false, type: Boolean },
    large: { default: false, type: Boolean },
    placeholder: { default: "Chọn xe", type: String },
    mandatory: { default: false, type: Boolean },
    fullWidth: { default: false, type: Boolean },
    type: { default: 'car', type: String },
    // items: {
    //   default: function() {
    //     return [];
    //   },
    //   type: Array,
    // },
  },
  computed: {
    display_text: function() {
      if (this.selected_items.length > 0) {
        if (this.selected_items.length == 1) {
          return this.selected_items[0].display_name;
        }

        if (this.selected_items.length == this.items.length) {
          return "Tất cả xe";
        }

        if (this.selected_items.length >= 1) {
          return " " + this.selected_items.length + " xe";
        }
      }
      return "Chọn xe";
    },
    displayItems() {
      let self = this;
      if (self.searchString) {
        return self.items.filter((i) =>
          i.display_name.includes(self.searchString)
        );
      } else {
        return self.items;
      }
    },
  },
  data() {
    return {
      showMenu: false,
      pageCount: 0,
      depsPage: 1,
      items: [
        // {
        //    dispaly_name: '',
        //    item_name: ''
        // }
      ],
      selected_items: [],
      searchString: "",
      loading: false,
      headers: [
        {
          text: "",
          value: "display_name",
          align: "start",
          class: " font-weight-bold dateMinWidth",
          sortable: true,
          width: "100",
        },
      ],
    };
  },
  methods: {
    closeMenu() {
      this.showMenu = false
      this.emitSelectedItems()
    },
    emitSelectedItems() {
      this.$emit("input", this.selected_items);
    },
    onRowClick (item) {
      if (!this.multiple) {
        this.selected_items = [item]
        this.closeMenu()
      }
    },
    fetchAllCars() {
      let self = this;
      self.items = [];
      self.selected_items = [];
      let paramsCars = {
        // created_at_min: moment(self.params.timeRange.start).toISOString(),
        // created_at_max: moment(self.params.timeRange.end).toISOString(),
        // show_deleted: true,
        enable: true,
        type: 'car'
      };
      self.axios
        .get(self.$root.apiAssetMana + "/cars", {
          params: paramsCars,
        })
        .then((res) => {
          if (res.data.status == "OK") {
            let customers = res.data.content.items;
            customers = customers.filter(item => item.type==self.type)
            customers.forEach((customer) => {
              if (!customer.is_owner) {
                self.items.push({
                  id: customer._id,
                  display_name: customer.name,
                  // item_name: employee.item_name
                });
              }
            });
            // self.setAllItemSelected();
            self.emitSelectedItems();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setAllItemSelected() {
      if(this.multiple){
        this.selected_items = this.items;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.fetchAllCars()
    });
  },
};
</script>

<style>
  .props-drop-down .v-btn::before {
    background-color: #ffffff !important;
  }

  .props-drop-down .v-btn i:hover {
    background-color: #ffffff !important;
  }

  .props-drop-down .v-btn--outlined {
    border: 1px solid #999999 !important;
  }

  .props-drop-down .v-btn--outlined:hover {
    border: 2px solid #444444 !important;
  }
</style>
