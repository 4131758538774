exports.routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../components/HomePage.vue"),
  },
  {
    path: "/roles",
    name: "RoleManager",
    component: () => import("@/views/Users/RoleManager.vue"),
    meta: "Quyền truy cập",
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../components/LoginPage.vue"),
  },
  {
    path: "/forget-password",
    name: "Forget Password",
    component: () => import("../components/ForgetPassword.vue"),
  },
  {
    path: "/user/profile",
    name: "UserProfile",
    component: () => import("../components/User/UserProfile.vue"),
    beforeEnter: (to, from, next) => {
      let userId = localStorage.getItem("uedi");
      if (userId) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/loyverse",
    name: "Loyverse",
    component: () => import("@/views/Loyverse/index.vue"),
    children: [
      {
        path: "report",
        name: "Loyverse Report",
        component: () => import("@/views/Loyverse/Report/index.vue"),
      },
    ],
  },
  {
    path: "/light-raw",
    name: "LightRaw",
    component: () => import("../components/LightRaw.vue"),
    meta: "Chiếu sáng",
  },
  {
    path: "/light",
    name: "Light",
    component: () => import("../components/Light.vue"),
    meta: "Chiếu sáng",
  },
  {
    path: "/light/report",
    name: "LightReport",
    component: () => import("../components/LightReportPage.vue"),
    meta: "Lịch sử chiếu sáng",
  },
  {
    path: "/light/setting",
    name: "LightSetting",
    component: () => import("../components/LightSetting.vue"),
    meta: "Cài đặt chiếu sáng",
  },
  {
    path: "/users",
    name: "Users",
    component: () => import("@/views/Users/Users.vue"),
    meta: "Danh sách nhân viên",
  },
  {
    path: "/users/:user_id",
    name: "User",
    component: () => import("@/views/Users/User.vue"),

    meta: "Nhân viên",
  },
  {
    path: "/access",
    name: "Access",
    component: () => import("../components/ZkAccess.vue"),
    meta: "QL Vào Ra",
  },
  {
    path: "/attendance",
    name: "Attendance",
    component: () => import("../components/ZkAttd.vue"),
    meta: "Chấm công",
  },
  {
    path: "/departments",
    name: "Departments",
    component: () => import("@/views/Departments/Departments.vue"),
    meta: "Danh sách phòng ban",
  },
  {
    path: "/departments/:dept_id",
    name: "Department",
    component: () => import("@/views/Departments/Department.vue"),
    meta: "Phòng Ban",
  },
  {
    path: "/zkdev/test",
    name: "ZKTest",
    component: () => import("@/views/ZKDev/ZkDevTest.vue"),
    meta: "ZK Test",
  },
  {
    path: "/zk-monitor",
    name: "ZkMonitor",
    component: () => import("../components/ZkMonitor.vue"),
    meta: "ZK Monitor",
  },
  /** Kho */
  {
    path: "/kho-monitor",
    name: "KhoMonitor",
    component: () => import("../components/KhoMonitor.vue"),
    meta: "Kho Monitor",
  },

  /** Room */
  {
    path: "/rooms",
    name: "List Rooms",
    component: () => import("@/views/Properties/Rooms/Rooms.vue"),
    meta: "Danh sách phòng",
  },
  {
    path: "/rooms-detail/:props_id",
    name: "Room Detail",
    component: () => import("@/views/Properties/Rooms/Room.vue"),
    meta: "Chi tiết phòng",
  },
  {
    path: "/rooms-sessions",
    name: "Rooms Sessions Admin",
    component: () => import("@/views/Sessions/Rooms/AdminRoomSessions.vue"),
    meta: "Quản lý thuê phòng",
  },
  {
    path: "/rooms-sessions/:session_id",
    name: "Rooms Session",
    component: () => import("@/views/Sessions/Rooms/RoomSession.vue"),
    meta: "Phòng cho thuê",
  },
  {
    path: "/rooms/rent-a-room",
    name: "Room A Room",
    component: () => import("@/views/Sessions/Rooms/UserRentARoom.vue"),
    meta: "Thuê Phòng",
  },
  {
    path: "/rooms-history",
    name: "Rooms History",
    component: () => import("@/views/Sessions/Rooms/UserRoomSessions.vue"),
    meta: "Lịch sử thuê phòng",
  },
  // {
  //   path: "/rooms-calendar",
  //   name: "Rooms Calendar",
  //   component: () => import("@/views/Sessions/Rooms/AdminCalendarRentRooms.vue"),
  //   meta: "Lịch thuê phòng",
  // },
  /** End Room */

  /** Studio */
  {
    path: "/studios",
    name: "List Studios",
    component: () => import("@/views/Properties/Studios/Studios.vue"),
    meta: "Danh sách phim trường",
  },
  {
    path: "/studios-detail/:props_id",
    name: "Studio Detail",
    component: () => import("@/views/Properties/Studios/Studio.vue"),
    meta: "Chi tiết phim trường",
  },
  {
    path: "/studios-sessions",
    name: "Studios Sessions",
    component: () =>
      import("@/views/Sessions/Studios/AdminStudiosSessions.vue"),
    meta: "Quản lý thuê phim trường",
  },
  {
    path: "/studios-sessions/:session_id",
    name: "Studio Session",
    component: () => import("@/views/Sessions/Studios/StudiosSession.vue"),
    meta: "Phim trường cho thuê",
  },
  {
    path: "/studios/rent-a-room",
    name: "Rent A Studio",
    component: () => import("@/views/Sessions/Studios/UserRentAStudio.vue"),
    meta: "Thuê Phim trường",
  },
  {
    path: "/studios-history",
    name: "Studios History",
    component: () => import("@/views/Sessions/Studios/UserStudiosSessions.vue"),
    meta: "Lịch sử thuê phim trường",
  },
  /** End Studio */

  /** Off Order */
  {
    path: "/off-sessions",
    name: "Off Sessions",
    component: () => import("@/views/Sessions/OffOrders/AdminOffSessions.vue"),
    meta: "Quản lý nghỉ phép",
  },
  {
    path: "/off-sessions/:session_id",
    name: "Off Session",
    component: () => import("@/views/Sessions/OffOrders/OffSession.vue"),
    meta: "Chi tiết xin nghỉ phép",
  },
  {
    path: "/off-submit-order",
    name: "Submit Off Order",
    component: () =>
      import("@/views/Sessions/OffOrders/UserSubmitOffOrder.vue"),
    meta: "Xin nghỉ phép",
  },
  {
    path: "/off-order",
    name: "Off-Order-History",
    component: () => import("@/views/Sessions/OffOrders/UserOffOrders.vue"),
    meta: "",
  },
  /** End Off Order */

  /** Equipments */
  {
    path: "/equipments",
    name: "Equipments",
    component: () => import("@/views/Properties/Equipments/Equipments.vue"),
    meta: "Danh sách thiết bị",
  },
  {
    path: "/equipments-on-lend",
    name: "Equipments",
    component: () => import("@/views/Properties/Equipments/EquipmentsOnLend.vue"),
    meta: "Thiết bị đang cho mượn",
  },
  {
    path: "/equipments/import",
    name: "ImportEquipment",
    component: () => import("@/views/Properties/Equipments/ImportEquiqment.vue"),
    meta: "Import Equiqments",
  },
  {
    path: "/equipts-detail/:props_id",
    name: "Equipment",
    component: () => import("@/views/Properties/Equipments/Equipment.vue"),
    meta: "Chi tiết thiết bị",
  },
  /** End Equipments */

  /** Cars */
  {
    path: "/cars",
    name: "Cars",
    component: () => import("@/views/Properties/Cars/Cars.vue"),
    meta: "Danh sách xe",
  },
  {
    path: "/cars-detail/:cars_id",
    name: "Car",
    component: () => import("@/views/Properties/Cars/Car.vue"),
    meta: "Chi tiết xe",
  },
  /** End Cars */
  /** Program */
  {
    path: "/programs",
    name: "Programs",
    component: () => import("@/views/Programs/Programs.vue"),
    meta: "Danh sách chương trình",
  },
  {
    path: "/programs-detail/:program_id",
    name: "Program",
    component: () => import("@/views/Programs/Program.vue"),
    meta: "Chi tiết chương trình",
  },
  /** End program*/
];
