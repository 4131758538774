<template>
  <v-container fluid >
    <v-row class="pa-0">
      <v-col class="pa-0" cols="12">
        <v-autocomplete
          v-model="resultProperties"
          :items="properties"
          outlined
          filled
          color="black"
          background-color="white"
          label="Chọn thiết bị"
          multiple
          hide-details
          chips
         deletable-chips
          @change="$emit('input', resultProperties)"
        >
        <!--
          <template v-slot:item="data">
            <template v-if="(typeof data.item !== 'object')">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-avatar>
                <img :src="!data.item.avatar? '/imgs/user-placehold.jpg': data.item.avatar">
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.fullname"></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
          -->
        </v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  data() {
    return {
      properties: [],
      resultProperties: [],
      value: null,
    };
  },
  computed: {
    ...mapGetters({
      userId: "userId",
      token: "token",
      role: "role",
      fullname: "getFullName",
    }),
    usersAfterFilter() {
      let result = [];
      result = this.users
      return result;
    },
  },
  methods: {
    removeInviter (item) {
      const index = this.resultProperties.indexOf(item._id)
      if (index >= 0) this.resultProperties.splice(index, 1)
    },
    async fetchProperties() {
      let paramsProperties = {
        enable: true,
        type: "thiet-bi",
        limit: 10000,
      }
      try {
         let res  = await this.axios.get(
            this.$root.apiAssetMana + "/properties",
            {
              headers: { "x-auth": this.token },
              params: paramsProperties
            })
         if (res.data.status == 'OK') {
            this.properties = res.data.content.items.map(item => item.name)
            this.properties = [...new Set(this.properties)]
            console.log(`properties ${this.properties.length}`)
            // console.log(this.properties)
         } else {
            alert(res.data.message)
         }
         
      } catch (err) {
         console.log(err)
      }
      
    },
  },
  mounted() {
    this.$nextTick(async () => {
      await this.fetchProperties()
    });
  },
};
</script>
