<template>
  <v-container fluid class="ma-0 pa-0">
    <v-row class="ma-0 my-1 pa-0">
      <div class="my-1 px-0">
        <div style="display:inline-block;vertical-align:bottom;font-size:12px;line-height:30px;">
          <!-- <v-icon>mdi-filter</v-icon> -->
          Trạng thái:
        </div>
        <div style="display: inline-block;">
          <v-chip
            v-for="(status, index) in filters"
            @click="
              status.selected = !status.selected;
              emitResult();
            "
            :outlined="!status.selected"
            color="pink"
            label
            dark
            small
            :class="{'px-2': $vuetify.breakpoint.smAndDown}"
            class="ml-1 mt-0"
            :key="index"
          >
            <v-icon left small style="font-size:14px;">
              {{ status.selected ? "mdi-check" : "" }}
            </v-icon>
            <span :style="{'font-size': $vuetify.breakpoint.smAndDown?'10px':'14px'}">
              {{ status.label }}
            </span>
          </v-chip>
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  /**Props:
   * filters: [{selected: Boolean, code: String, lable: String}]
   */
  props: {
    filters: {
      type: Array,
    },
  },
  data() {
    return {
      resultFilters: [],
    };
  },
  methods: {
    emitResult() {
      let result = this.filters.filter((s) => s.selected).map((s) => s.code);
      this.$emit("change-filters", result)
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.emitResult()
    });
  },
};
</script>
