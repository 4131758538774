import Toolbar from "../components/core/Toolbar.vue";
import ToolbarNavigator from "../components/core/ToolbarNavigator.vue";
import Navigation from "../components/core/NavigationDrawer.vue";
import DatePicker from "../components/atom/DatePicker.vue";
import LineChartPoints from "../components/atom/LineChartPoints.vue";
import UserDoorState from "../components/User/UserDoorState.vue";
import DoorUsers from "../components/User/DoorUsers.vue";
import DoorAttds from "../components/User/DoorAttds.vue";
import ReportAttds from "../components/User/ReportAttds.vue";
import UsersSelector from "../components/User/UsersSelector.vue";
import ShowFullImage from "../components/atom/ShowFullImage.vue";
import FilterArray from "../components/atom/FilterArray.vue";
import RoomsPropertyPicker from "../components/Properties/Rooms/RoomsPicker.vue";
import StudiosPropertyPicker from "../components/Properties/Studios/StudiosPicker.vue";

import PanelPickEquipment from "../components/Properties/Equipments/PanelPickEquipment.vue";
import EquipmentsPicker from "../components/Properties/Equipments/EquipmentsPicker.vue";
import EquipmentHistoryDamage from "../components/Properties/Equipments/EquipmentHistoryDamage.vue";
import EquipmentHistoryLend from "../components/Properties/Equipments/EquipmentHistoryLend.vue";

import DivAddDamage from "../components/Properties/Equipments/DivAddDamage.vue";
import CarsPicker from "../components/Properties/Cars/CarsPicker.vue";
import ProgramsPicker from "../components/Programs/ProgramPicker.vue";
import DateRangePicker from "../components/atom/DateRangePicker.vue";
import ReasonDialog from "../components/atom/ReasonDialog.vue";
import InviterUser from "../components/User/InviterUser.vue";
import SelectUser from "../views/Users/DialogSelectUser.vue";
import Calendar from "../components/atom/Calendar.vue";
import PickEquipmentByName from "../components/Properties/Equipments/PickEquipmentByName.vue";


function setupComponents(Vue) {
  Vue.component("toolbar", Toolbar);
  Vue.component("navigation", Navigation);
  Vue.component("toolbar-navigator", ToolbarNavigator);
  Vue.component("date-picker", DatePicker);
  Vue.component("line-chart-points", LineChartPoints);
  Vue.component("door-users", DoorUsers);
  Vue.component("door-attds", DoorAttds);
  Vue.component("report-attds", ReportAttds);
  Vue.component("user-selector", UsersSelector);
  Vue.component("user-door-state", UserDoorState);
  Vue.component("show-full-image", ShowFullImage);
  Vue.component("filter-array", FilterArray);

  Vue.component("room-property-picker", RoomsPropertyPicker);
  Vue.component("studio-property-picker", StudiosPropertyPicker);
  Vue.component("equipt-property-picker", EquipmentsPicker);
  Vue.component("panel-pick-equipment", PanelPickEquipment);
  Vue.component("pick-equipment-by-name", PickEquipmentByName);
  Vue.component("car-property-picker", CarsPicker);
  Vue.component("program-picker", ProgramsPicker);

  Vue.component("equipment-histoty-damage", EquipmentHistoryDamage);
  Vue.component("equipment-history-lend", EquipmentHistoryLend);
  Vue.component("div-add-damage", DivAddDamage);

  Vue.component("date-range-picker", DateRangePicker);
  Vue.component("reason-dialog", ReasonDialog);
  Vue.component("inviter-user", InviterUser);
  Vue.component("select-user", SelectUser);
  Vue.component("mcv-calendar", Calendar);

}

export { setupComponents };
