import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import vuetify from "./plugins/vuetify";
import VCalendar from "v-calendar";
import VueSocketIO from 'vue-socket.io'
import { setupComponents } from "./config/setup-components";
import VueChart from 'vue-chart-js'
// import VueSvgGauge from 'vue-svg-gauge'
const moment = require('moment')
require('moment/locale/vi')

let serverHostSocket = "https://otaio.pono.vn"

setupComponents(Vue);

Vue.config.productionTip = false;
Vue.use(VueSocketIO, serverHostSocket)
Vue.use(VueAxios, axios);
Vue.use(VueChart)
Vue.use(require('vue-moment'), moment)
// Vue.use(VueSvgGauge)

Vue.use(VCalendar, {
  componentPrefix: "vc", // Use <vc-calendar /> instead of <v-calendar />
  locale: "vi", // ...other defaults
});

/*eslint-disable */
// let serverHostSocket = coreGetServerHost()
// let serverAPI = "" + coreGetServerHost() + "/"
/*eslint-enable */
// console.log("Server API " + serverAPI)

// Vue.use(VueSocketIO, serverHostSocket)

Vue.mixin({
  mounted: function () {
      let self = this
      setTimeout(function(){
        let allow = false
        if(!self.roles) 
        { 
          allow = true
        } else {
          if (self.$root.roles.length<=0) { 
            allow = false
          } else {
            self.$root.roles.forEach(role => {
              if(self.roles.includes(role)){
                allow = true
              }
            })
          }
        }
        if(!allow) {
          self.$router.push({path: '/'})
        }
      }, 2000)
  }
})

const EventBus = new Vue();
Vue.prototype.$eventBus = EventBus;

new Vue({
  router,
  store,
  vuetify,
  data: {
    roles: [],
    nhanSuRoleUser: ['602bec3c6e6a5e20bd935c54', '602beafe6e6a5e20bd935c50'],
    apiUserCustomId: "mcv-2021-r1-env",
    devCode: "PN-MCV-LIGHT",
    apiLoyverse: "https://devmcvusr.duocdo.com/v1/loyverse",
    apiUser: "https://devmcvusr.duocdo.com/v1",
    apiRec: "http://localhost:3044/",
    apiGate: "http://localhost:4099/",
    apiZK: "http://localhost:5088/",

    apiAssetMana: "https://devmcvproperty.duocdo.com",
    // apiAssetMana: "http://localhost:3035"
    
  },
  render: (h) => h(App),
}).$mount("#app");
