<template>
    <v-timeline
        align-top
        dense
      >
      <v-timeline-item
         v-for="(item, index) in history"
         :key="index"
         color="teal"
         small
        >
         <v-row class="pt-1"
            v-if="item">
            <v-col cols="3">
               <strong>{{$moment(item.date).format("DD/MM/YY, HH:mm")}}</strong>
            </v-col>
            <v-col>
               <strong>{{item.UserId}}</strong>
               <div class="my-1">
                  <i>{{item.note}}</i>
               </div>
               <div v-if="item.images">
                  <v-img v-for="(img, imgIndex) in item.images"
                     :key="imgIndex"
                     @click="showImageFullScreen(img)"
                     contain
                     style="width:64px;height:64px;margin: 2px;display:inline-block;border-radius:4px;border:1px solid #E2E2E2;"
                     :src="img"/>
               </div>
            </v-col>
         </v-row>
      </v-timeline-item>
      <show-full-image
         :show="showFullImage"
         :image="imgFullScreen"
         @close-dialog-show-fullimage="showFullImage = false"
      ></show-full-image>
   </v-timeline>
</template>

<script>
/* history item
   {
      date: Date,
      users: [{UserId}],
      note: String,
      images: [],
      note_by: UserId
   }
*/
export default {
   props: ['history'],
   data () {
      return {
         showFullImage: false,
         imgFullScreen: ''
      }
   },
   methods: {
      showImageFullScreen(link) {
         this.showFullImage = true
         this.imgFullScreen = link
      },
   }
}
</script>