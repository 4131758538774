var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"parent",staticStyle:{"width":"100%","padding":"4px 8px","overflow-x":"auto","overflow-y":"hidden"},style:({
        'min-height': _vm.height  + 'px', 'max-height': _vm.height  + 'px',
    })},[(_vm.title && _vm.title.length)?_c('div',{staticStyle:{"width":"100%","text-align":"center","height":"34px","color":"#2E7D32","font-size":"20px","line-height":"34px","font-weight":"600","background-color":"#A5D6A7"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.showChart)?_c('div',{staticStyle:{"height":"100%","width":"100%","margin-top":"4px"},style:({
           'min-width': _vm.chartMinWidth,
           'min-height': _vm.height + 'px', 'max-height': _vm.height  + 'px',
        })},[_c('vue-chart',{staticStyle:{"width":"100%"},style:({
                'min-width': _vm.chartMinWidth,
                'min-height': (_vm.height-32)+'px', 'max-height': (_vm.height-32)+'px',
            }),attrs:{"type":"line","data":_vm.chartData,"options":_vm.chartOptions}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }