<template>
  <v-toolbar
      dark
      app
      :color="$root.themeColor"
      >
      <v-toolbar-title>
         <v-toolbar-side-icon flat :color="$root.themeTextColor" @click="toggleNavigationBar"></v-toolbar-side-icon>
      </v-toolbar-title>
      <!-- <breadcrumbs /> -->
      <v-spacer></v-spacer>

      <!--
      <v-menu offset-y origin="center center" :nudge-bottom="10" transition="scale-transition">

      <v-btn icon flat slot="activator" @click="notifications.map(x => x.isActive = false)">
         <v-badge color="red" overlap>
            <span slot="badge" v-if="notifications.filter(x => x.isActive).length > 0">
               {{ notifications.filter(x => x.isActive).length }}
            </span>
            <v-icon v-bind:style="{color: $root.themeTextColor}" medium>notifications</v-icon>
         </v-badge>
      </v-btn>

      <v-card class="elevation-0">
         <v-toolbar card dense color="transparent">
            <v-toolbar-title><h5 style="font-size:13px;">Bạn có {{ notifications.length }} thông báo mới</h5></v-toolbar-title>
         </v-toolbar>
         <v-card-text class="pa-0">
            <v-list two-line class="pa-0 ma-0" style="max-height:400px;" dense>
            <template v-for="(item, index) in notifications">
            <v-divider :key="index+1000" />
            <v-list-tile avatar :key="index" class="pa-0 ma-0">
               <v-list-tile-avatar size="28" :color="item.color">
                  <v-icon small dark class="pa-0 ma-0">{{item.icon}}</v-icon>
               </v-list-tile-avatar>
               <v-list-tile-content  class="pa-0 ma-0">
                  <div style="font-size:11px;color:#333333;line-height:12px;overflow-wrap:white-space;" v-html="item.title">
                  </div>
               </v-list-tile-content>
               <v-list-tile-action style="font-size:10px;color:#999999;">
                  {{item.eventAt}}
               </v-list-tile-action>
            </v-list-tile>
            </template>
            </v-list>
            <v-divider></v-divider>
            <v-btn block flat color="primary"
            style="text-transform:none;margin-top:2px;margin-bottom:2px;height:32px;"
            @click="goToAllAlarmPage">
            Xem lịch sử cảnh báo</v-btn>
            <v-divider></v-divider>
         </v-card-text>
      </v-card>
      </v-menu>
      <v-menu offset-y origin="center center" :nudge-bottom="10" transition="scale-transition">
      <v-btn flat slot="activator"
         color="#555555"
         style="margin:0px;font-size:13px;width:54px;min-width:30px;padding:0px;" :ripple="false">
         <div style="overflow: hidden;margin:0px;padding:0px;width:100%;height:auto;">
            {{name}}
         </div>
      </v-btn>
      <v-list>
         <v-list-tile
            v-for="(item,index) in items"
            :key="index"
            :to="!item.href ? { name: item.name } : null"
            :href="item.href"
            ripple="ripple"
            :disabled="item.disabled"
            :target="item.target"
            @click="item.click">
            <v-list-tile-action v-if="item.icon">
            <v-icon>{{ item.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
            <v-list-tile-title>{{ item.title }}</v-list-tile-title>
            </v-list-tile-content>
         </v-list-tile>
      </v-list>
      </v-menu>
      <v-dialog v-model="dialogUserProfile"
      fullscreen>
      <v-card>
         <v-card-title
            style="backgroundColor:#1565C0;color:white;font-size:18px;">
            Thông tin người dùng
            <v-spacer/>
            <v-btn dark icon flat @click="dialogUserProfile=false;"
            style="margin:0px;">
            <v-icon>clear</v-icon>
            </v-btn>
         </v-card-title>
         <v-card-text 
            style="font-size:16px;">
            <v-container
            fluid grid-list-xs 
            justify-center
            align-center>
            <v-layout row wrap style="">
               <v-flex md3 xs0 style="padding:0px;">
               </v-flex>
               <v-flex md6 xs12 style="padding:12px;border: 1px solid #EEEEEE;border-radius:12px;">
                  <div style="display:block;padding:6px;">
                  <div style="display:inline-block;width:180px;font-weight:350;">Tài khoản:</div>
                  <div style="display:inline-block;font-weight:600;">{{name}}</div>
                  </div>
                  <div style="display:block;padding:6px;">
                  <div style="display:inline-block;width:180px;font-weight:350;">Quyền truy cập:</div>
                  <v-btn dark round small :color="userRoleObj.roleColor" depressed
                        style="text-transform:none;margin: 0px;font-size:12px;width:80px;min-width:50px;height:22px;">
                        {{ userRoleObj.roleStr }}
                  </v-btn>
                  </div>
               </v-flex>
            </v-layout>
            </v-container>
         </v-card-text>
      </v-card>
      </v-dialog>
      -->
  </v-toolbar>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
        name: 'name',
        userId: "userId",
        token: "token",
        role: "level"
    }),
    userRoleObj () {
      if(this.role === 'SupAdmin') return { roleStr: 'Siêu quản trị', roleColor: "indigo" }
      if(this.role === 'Admin') return { roleStr: 'Quản trị', roleColor: "red" }
      if(this.role === 'Operator') return { roleStr: 'Nhân viên', roleColor: "blue"}
      if(this.role === 'Engineer') return { roleStr: 'Ks lắp đặt', roleColor: "orange" }
      if(this.role === 'Guest') return { roleStr: 'Khách', roleColor: "teal" }
      return { roleStr: 'Khách', roleColor: "teal" }
                
    }
  },
//   sockets: {
//     newEvents: function(events){
//       if(events && events.length>0){
//         events.forEach(event => {
//           let type = {bg:'#FFCC80EE', border: '#FFA000EE'}
//           if(event.tags){
//             if(event.tags.indexOf('danger')>-1) {
//               type = {bg: '#5C6BC0EE', border: '#3949ABEE'}
//             } else if(event.tags.indexOf('error')>-1) {
//               type = {bg: '#FF8A65EE', border: '#F4511EEE'}
//             }
//           }
//           this.notifications.unshift(
//             {
//               title: event.device.name + ' - ' +  (event.description || event.name),
//               color: type.border,
//               icon: 'warning',
//               eventAt: moment(event.recordDate).format('HH:mm'),
//               date: new Date(event.recordDate),
//               isActive: true
//             })
//         })
//       }
//       if(this.notifications.length>12){
//         this.notifications = this.notifications.slice(0,11)
//       }
//     }
//   },
  data() {
    return {
      dialog: false,
      dialogUserProfile: false,
      switchEmailNotification: true,
      showPassword: null,
      showPasswordConfirm: null,
      userEmail: null,
      password: null,
      passwordConfirm: null,
      error: false,
      showSnackbar: false,
      snackbarMessage: '',
      snackbarColor: 'info',
      items: [
        {
          icon: 'settings',
          href: '#',
          title: 'Cá nhân',
          click: () => {
            const vm = this;
            vm.dialogUserProfile = true;
          }
        },
        {
          icon: 'exit_to_app',
          href: '#',
          title: 'Đăng xuất',
          click: () => {
            const vm = this;
            vm.$store
              .dispatch("logout")
              .then(() => {
                  location.reload(true)
              });
          }
        }
      ],
      notifications:
      [
      ]
    }
  },
  methods: {
    toggleNavigationBar() {
      const vm = this;
      vm.$eventBus.$emit('toggleNavigationBar');
    },
    goToAllAlarmPage() {
      this.$router.push({ name: 'AllAlarm' });
    }
  }
};
</script>

<style>
  .v-list--two-line.v-list--dense .v-list__tile {
    height: 46px;
  }
</style>

<style lang="scss">
  .custom-template {
    display: flex;
    color: #444444;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: left;
    font-size: 13px;
    margin: 3px;
    margin-bottom: 0;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    &, & > div {
      box-sizing: border-box;
    }
    // background: #E8F9F0;
    // border: 2px solid #D0F2E1;
    border-width: 2px;
    border-style: solid;
    .custom-template-icon {
      // flex: 0 1 auto;
      color: #FFFFFF;
      // font-size: 32px;
      padding: 0 10px;
    }
    .custom-template-close {
      flex: 0 1 auto;
      padding: 0 20px;
      font-size: 16px;
      opacity: 0.7;
      cursor: pointer;
      &:hover {
        opacity: 1.0;
      }
    }
    .custom-template-content {
      padding: 6px;
      flex: 1 0 auto;
      .custom-template-title {
        letter-spacing: 1px;
        // text-transform: uppercase;
        font-size: 10px;
        font-weight: 600;
      }
    }
  }
  .v-fade-right-enter-active,
  .v-fade-right-leave-active,
  .v-fade-right-move {
    transition: all .5s;
  }
  .v-fade-right-enter,
  .v-fade-right-leave-to {
    opacity: 0.3;
    transform: translateY(-30px);
  }

</style>
