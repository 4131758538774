<template>
   <div>
      <template
         v-for="item in items"
      >
         <template >
            <v-btn v-if="!item.childs ||  item.childs.length<=0"
               :key="item.name"
               color="white" class="px-2"
               style="text-transform:none;font-weight:400;"
               text
               @click="changeRoute(item.name, item.index, 0)"
            >
               {{item.displayName}}
            </v-btn>
            <v-menu v-else
               :key="item.name"
               open-on-hover
               offset-y>
               <template v-slot:activator="{ on }">
                  <v-btn
                     color="white" class="px-2"
                     style="text-transform:none;font-weight:400;"
                     text
                     v-on="on"
                  >
                     {{item.displayName}}
                     <v-icon>arrow_drop_down</v-icon>
                  </v-btn>
               </template>
               <v-list style="border-radius:0px;z-index:100;">
                  <v-list-item-group>
                     <v-list-item style="z-index:100;"
                        v-for="childPage in item.childs"
                        :key="childPage.name"
                        @click="changeRoute(childPage.name, childPage.index, childPage.selectedChildIndex)"
                     >
                        <v-list-item-title 
                           style="font-size:15px;"
                           class="cursor-pointer">
                           {{childPage.displayName}}
                        </v-list-item-title>
                     </v-list-item>
                  </v-list-item-group>
               </v-list>
            </v-menu>
         </template>
      </template>
   </div>
</template>

<script>
import {mapGetters } from "vuex";
export default {
   computed: {
      ...mapGetters(["adminRole"])
   },
   props: {
      value: {
         type: Boolean,
         required: false,
         default: true
      }
   },
   watch: {
   },
   data() {
         return {
            displayMode : true,
            selectedIndex: 1,
            selectedChildIndex: 1,
            items: require('../../mkdata/navigator').navigators
         }
   },
   mounted() {
      let self = this;
      this.selectedIndex = 1
      this.items = this.items.filter(i => !i.adminOnly || this.adminRole)
      this.items.forEach(item => {
         if(item.name==self.$route.name){
            this.selectedIndex = item.index
         }
         if(item.childs){
            item.childs = item.childs.filter(ch => !ch.adminOnly || this.adminRole)
            item.childs.forEach(child => {
               if(child.name==self.$route.name){
                  this.selectedIndex = child.index
                  this.selectedChildIndex = child.selectedChildIndex
               }
            })
         }
      })
   },
   methods: {
      changeRoute(routeName, selectedIndex, childSelectedIndex) {
         const vm = this;
         vm.selectedIndex = selectedIndex;
         vm.selectedChildIndex = childSelectedIndex
         try {
            console.log("push " + routeName)
            vm.$router.push({ name: routeName })
         } catch(err) {console.log(err)}
      }
   }
}
</script>

<style>
  .active-tile {}
  .active-child {
    font-weight: 600;
    color: "#FFFFFF44"
  }
  .cursor-pointer {
     cursor: pointer;
     
  }
  .cursor-pointer:hover {
     color: #000000;
     font-weight: 600;
  }
</style>