<template>
      <v-card>
         <v-card-title>
            Select a User
            <v-spacer/>
            <v-btn icon
               @click="$emit('closeMe')">
               <v-icon>
                  clear
               </v-icon>
            </v-btn>
         </v-card-title>
         <v-card-text>
            <div>
               <v-text-field
                  v-model="searchString"
                  append-icon="mdi-magnify"
                  label="Search"
                  clearable
                  outlined
                  dense
                  single-line
                  hide-details
                  style="max-width:320px;"
               ></v-text-field>
            </div>
            <v-data-table
               flat
               v-model="selected_users"
               :headers="headers"
               :items="users"
               :loading="loading"
               loading-text="Loading... Please wait"
               no-data-text="Chưa có nhân viên nào"
               item-key="_id"
               :items-per-page="pageSize"
               dense
               class="elevation-0"
               :search="searchString"
               :page.sync="usersPage"
               @page-count="pageCount = $event"
               hide-default-footer
               @click:row="handleRowClick"
               >
            </v-data-table>
            <div v-if="pageCount>1"
              class="text-center pt-2">
              <v-pagination
                  v-model="usersPage"
                  :length="pageCount"
                  ></v-pagination>
            </div>
         </v-card-text>
      </v-card>
</template>

<script>
export default {
   data () {
      return {
         pageCount: 0,
         usersPage: 1,
         users: [],
         selected_users: [],
         departments: [],
         pageSize: 15,
         loading: false,
         searchString: "",
         headers: [
            {
               text: "Mã NV",
               align: "start",
               sortable: true,
               value: "user_code",
               width: 100,
            },
            { text: "Tên", align: "start", sortable: true, value: "fullname" },
         ]
      }
   },
   methods: {
      fetchUsers() {
         let self = this;
         self.selected_users = [];
         let pathFetchDataUsers =
         self.$root.apiUser + "/users/list/" + self.$root.apiUserCustomId;

         self.loading = true;
         Promise.all([
            self.axios.get(pathFetchDataUsers)
         ])
         .then((responseArray) => {
            console.log("Promise ALL",responseArray );
            if (responseArray[0].data.status == "OK") {
               self.users = responseArray[0].data.content.items;
            }
         })
         .catch((err) => {
            console.log(err);
         })
         .finally(() => {
            // self.processTotalReport();
            self.loading = false;
         });
      },
      handleRowClick (item) {
         this.$emit('onUserSelected', item)
         // console.log('handleRowClick')
         // console.log(item)
      }
   },
   mounted() {
    this.$nextTick(() => {
      this.fetchUsers();
    });
  },
}
</script>