<template>
  <v-dialog v-model="show" persistent hide-overlay width="500px">
    <v-card class="mx-auto" max-width="100%" width="500px">
      <v-card-title
        class="pa-0 pa-1 px-4 ma-0 teal"
        style="color:white;font-size: 18px;"
        >{{ title }}
        <v-spacer></v-spacer>
        <v-btn
          text
          class="text-capitalize bold"
          color="white"
          @click.native="$emit('cancel-fill-reason')"
          icon
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text class="pa-4 py-2">
        <v-form class="pa-0  ma-0" ref="reasonForm" v-model="formValid">
          <v-textarea
            class="my-2"
            color="teal"
            v-model="result"
            :rules="[rules.required]"
            placeholder="Lý do *"
            type="text"
            outlined
            
            auto-grow
            :row="3"
            dense
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-4 pt-0 pb-2 ma-0">
        <v-spacer></v-spacer>

        <v-btn
          class="text-capitalize bold"
          text
          color="teal"
          @click.native="send"
          style="font-size: 16px;"
          >Lưu</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: { type: Boolean, default: false },
    title: {
      type: String,
      default: "",
    },
  },
  watch:{
    show(){
      this.resetValidate()
    }
  },
  data() {
    return {
      formValid: "",
      result: "",
      rules: {
        required: (value) => !!value || "Yêu cầu không để trống!",
      },
    };
  },
  methods: {
    send() {
      if (!this.isValidate()) return;
      this.$emit("fill-reason-done", this.result);
    },
    resetValidate() {
      this.$refs.reasonForm.reset();
    },
    isValidate() {
      let result = true;
      if (!this.$refs.reasonForm.validate()) {
        result = false;
      }
      return result;
    },
  },
};
</script>
