<template>
   <div>
      <div style="font-size:15px;font-weight: 500;line-height:32px;">
         Số nhân viên được vào: 
         <span class="ml-4"
            style="font-family:Lucida Sans Unicode;font-weight:600;font-size:18px;">
            {{users.length}}</span>
      </div>
      <div class="py-2">
         <v-text-field
            v-model="userSearchStr"
            append-icon="mdi-magnify"
            label="Search"
            clearable
            outlined
            dense
            single-line
            hide-details
            style="width:100%;max-width:420px;"
            ></v-text-field>
      </div>
      <v-data-table
         :headers="usersHeader"
         :items="users"
         :page.sync="usersPage"
         :items-per-page="19"
         hide-default-footer dense
         class="elevation-1"
         @page-count="pageCount = $event"
         :search="userSearchStr"
         :mobile-breakpoint="0"
      >
         <template v-slot:item.name="{ item }">
               <a v-if="item._id && item._id.length>0" :href="'/users/' + item._id">
                  {{ item.name }}
               </a>
               <div v-else>
                  {{ item.name }}
               </div>
            </template>
         <template v-slot:item.actions="{ item }">
            <v-btn icon color="red"
               style="height:30px;width:30px;"
               @click="removeItemOutOfDoor(item)">
               <v-icon style="font-size:18px;">
                  mdi-close
               </v-icon>
            </v-btn>
         </template>
      </v-data-table>
      <div v-if="pageCount>1"
         class="text-center pt-2">
         <v-pagination
            v-model="usersPage"
            :length="pageCount"
            ></v-pagination>
      </div>
   </div>
</template>

<script>
export default {
   props: ['users', 'floor'],
   sockets: {
      'deleteUser-REPLY': function (payload) {
         this.loading = false
         if(payload && payload.askKey == this.socketAskKey){
            this.result = payload.result
            console.log('deleteUser-REPLY')
            console.log(this.result)
            // alert('Delete result ' + this.result)

            this.$emit("user-delete-done")

            this.$forceUpdate()
         }
      },
   },
   data () {
      return {
         pageCount: 0,
         usersPage: 1,
         userSearchStr: '',
         usersHeader: [
            {
               text: 'Mã NV',
               align: 'start',
               sortable: false,
               value: 'userId',
               // value: 'user_code',
               width: '70px',
            },
            { text: 'Họ và Tên', value: 'name' },
            { text: '', value: 'matchS' },
            { text: '', value: 'actions' }
         ],
      }
   },
   methods: {
      removeItemOutOfDoor(item){
         if(confirm("Xóa nhân viên " + item.name + ' khỏi khu ' + this.floor.name + "?")){
            this.floor.ips.forEach(ip => {
               if(!ip|| !item.userId) return
               this.result = "..."
               this.loading = true
               this.sendTime = new Date()
               this.$socket.emit('sendMagicCommand', 
               {
                  command: "deleteUser",
                  askKey: this.socketAskKey,
                  devCode: "MCVZK-" + ip,
                  paras: {
                     userId: item.userId
                  }
               })
            }) 
         }
      }
   }
}
</script>

<style>
   
</style>