import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    name: localStorage.getItem("uuse") || "",
    userId: localStorage.getItem("uedi") || "",
    token: localStorage.getItem("ttk") || "",
    role: localStorage.getItem("leus") || "",
    mute: localStorage.getItem("teum") || "",
    //notiBlackList: localStorage.getItem("blnoti") || "[]",
    notiBlackList: JSON.parse(
      localStorage.getItem("blnoti") ? localStorage.getItem("blnoti") : "[]"
    ),
  },
  getters: {
    getFullName: (state) => {
      // console.log("Getter Name: ", state.name);
      return state.name;
    },
    userId: (state) => {
      return state.userId;
    },
    token: (state) => {
      return state.token;
    },
    mute: (state) => {
      return state.mute;
    },
    role: (state) => {
      return state.role;
    },
    notiBlackList: (state) => {
      if (!state.notiBlackList) return [];
      return state.notiBlackList;
    },
  },
  mutations: {
    addUser(state, payload) {
      state.name = payload.name;
      state.userId = payload.userId;
      state.role = payload.role;
      state.token = payload.token;
    },
    logOut(state) {
      state.name = "";
      state.userId = "";
      state.token = "";
      state.role = "";
    },
    fetchUserId: (state, userId) => {
      state.userId = userId;
    },
    muteAlarm(state) {
      state.mute = "1";
    },
    unmuteAlarm(state) {
      state.mute = "0";
    },
    addNotiToBlackList(state, payload) {
      if (!state.notiBlackList) {
        state.notiBlackList = [];
      }
      if (!state.notiBlackList.includes(payload.name)) {
        state.notiBlackList.push(payload.name);
      }
      localStorage.setItem("blnoti", JSON.stringify(state.notiBlackList));
    },
    removeNotiToBlackList(state, payload) {
      if (!state.notiBlackList) {
        state.notiBlackList = [];
      }
      state.notiBlackList = state.notiBlackList.filter(
        (name) => name != payload.name
      );
      localStorage.setItem("blnoti", JSON.stringify(state.notiBlackList));
    },
    fetchLocalStorage: (state, payload) => {
       state.name = payload.name;
       state.userId = payload.userId;
       state.role = payload.role;
       state.token = payload.token;
        state.mute = payload.mute;
    },
  },
  actions: {
    save: (context, payload) => {
      return new Promise((resolve) => {
        context.commit("addUser", {
          name: payload.name,
          role: payload.role,
          userId: payload.userId,
          token: payload.token,
        });
        localStorage.setItem("uuse", payload.name);
        localStorage.setItem("uedi", payload.userId);
        localStorage.setItem("leus", payload.role);
        localStorage.setItem("ttk", payload.token);
        resolve({ status: "OK" });
      });
    },
    logout: (context) => {
      return new Promise((resolve /* reject */) => {
        context.commit("logOut");
        localStorage.removeItem("kec_ginol");
        localStorage.removeItem("uuse");
        localStorage.removeItem("uedi");
        localStorage.removeItem("leus");
        localStorage.removeItem("ttk");
        resolve();
      });
    },
    fetchUserId: (context) => {
      context.commit("fetchUserId", localStorage.getItem("uedi"));
    },
    addNotiToBlackList: (context, payload) => {
      return new Promise((resolve) => {
        context.commit("addNotiToBlackList", payload);
        resolve({ status: "OK" });
      });
    },
    removeNotiFromBlackList: (context, payload) => {
      return new Promise((resolve) => {
        context.commit("removeNotiToBlackList", payload);
        resolve({ status: "OK" });
      });
    },
    fetchLocalStorage: (context) => {
      return new Promise((resolve) => {
        context.commit("fetchLocalStorage", {
          name: localStorage.getItem("uuse") || "",
          userId: localStorage.getItem("uedi") || "",
          token: localStorage.getItem("ttk") || "",
          role: localStorage.getItem("leus") || "",
          mute: localStorage.getItem("teum") || "",
        });
        resolve({ status: "OK" });
      });
    },
  },
  modules: {},
});
