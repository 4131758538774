let navigators = [
  {
    title: "Phân quyền",
    to: "/roles",
    color: "#0D47A1",
    icon: "mdi-account-cancel-outline",
    active: false,
    allows: ["admin"],
    blocks: [],
  },
  {
    title: "Chiếu sáng",
    to: "/light",
    color: "orange",
    icon: "mdi-lightbulb-on-outline",
    active: false,
    allows: ["light-manager", "admin"],
    blocks: [],
  },
  {
    title: "Canteen",
    to: "/loyverse/report",
    color: "#3F51B5",
    icon: "mdi-noodles",
    active: false,
    allows: ["canteen", "admin"],
    blocks: [],
  },
  {
    title: "Giám sát cửa",
    to: "/zk-monitor",
    color: "pink",
    icon: "mdi-monitor-eye",
    active: false,
    allows: ["admin"],
    blocks: [],
  },
  {
    title: "Cấp phép vào cửa",
    to: "/access",
    color: "green",
    icon: "mdi-door-closed-lock",
    active: false,
    allows: ["access-manager", "admin"],
    blocks: [],
  },
  {
    title: "Chấm công",
    to: "/attendance",
    color: "brown",
    icon: "mdi-calendar-month",
    active: false,
    allows: ["timekeeper-manager", "admin"],
    blocks: [],
  },
  // {
  //   title: "Báo cáo",
  //   to: "/",
  //   icon: "mdi-chart-bar",
  //   color: "teal",
  //   active: false,
  //   subs: [
  //     {
  //       title: "Hóa đơn",
  //       to: "/loyverse/report",
  //       icon: "mdi-menu-right",
  //       color: "teal",
  //     },
  //   ],
  // },
  // {
  //   title: "Nhân viên",
  //   to: "/users",
  //   color: "#455A64",
  //   icon: "mdi-account-circle",
  //   active: false,
  // },
  {
    title: "Nhân viên",
    to: "/users",
    color: "#455A64",
    icon: "mdi-account-circle",
    active: false,
    allows: ["user-manager", "admin"],
    blocks: [],
  },
  {
    title: "Phòng Ban",
    to: "/departments",
    color: "#2196F3",
    icon: "mdi-window-open-variant",
    active: false,
    allows: ["user-manager", "admin"],
    blocks: [],
  },
  {
    title: "Danh sách phòng họp",
    to: "/rooms",
    color: "#AD1457",
    icon: "mdi-gold",
    active: false,
    allows: ["meeting-room-manager", "admin"],
    blocks: [],
  },
  {
    title: "Quản lý phòng họp",
    to: "/rooms-sessions",
    color: "#AD1457",
    icon: "mdi-text-box-check",
    active: false,
    allows: ["meeting-room-manager", "admin"],
    blocks: [],
  },
  {
    title: "Ds chương trình",
    to: "/programs",
    color: "#37474F",
    icon: "mdi-filmstrip",
    active: false,
    allows: ["program-manager", "admin"],
    blocks: [],
  },
  {
    title: "Danh sách thiết bị",
    to: "/equipments",
    color: "#37474F",
    icon: "mdi-lock-smart",
    active: false,
    allows: ["equipments-manager", "admin"],
    blocks: [],
  },
  {
    title: "Danh sách xe",
    to: "/cars",
    color: "#C62828",
    icon: "mdi-car-convertible",
    active: false,
    allows: ["vehicles-manager", "admin"],
    blocks: [],
  },
  {
    title: "Danh sách phim trường",
    to: "/studios",
    color: "#FF6D00",
    icon: "mdi-factory",
    active: false,
    allows: ["studio-manager", "admin"],
    blocks: [],
  },
  {
    title: "Quản lý lịch quay",
    to: "/studios-sessions",
    color: "#FF6D00",
    icon: "mdi-video-vintage",
    active: false,
    allows: ["lich-quay-manager", "vehicles-manager", "admin"],
    blocks: [],
  },
  {
    title: "Quản lý nghỉ phép",
    to: "/off-sessions",
    color: "#9E9D24",
    icon: "mdi-account-off",
    active: false,
    allows: ["user-off-manager", "user-manager", "admin"],
    blocks: [],
  },
  {
    title: "Đăng ký phòng họp",
    to: "/rooms/rent-a-room",
    color: "#AD1457",
    icon: "mdi-newspaper-plus",
    active: false,
    allows: null,
    blocks: null,
  },
  {
    title: "Lịch sử phòng họp",
    to: "/rooms-history",
    color: "#AD1457",
    icon: "mdi-home-plus",
    active: false,
    allows: null,
    blocks: null,
  },
  {
    title: "Đăng ký địa điểm quay",
    to: "/studios/rent-a-room",
    color: "#FF6D00",
    icon: "mdi-video-wireless",
    active: false,
    allows: null,
    blocks: null,
  },
  {
    title: "Lịch sử lịch quay",
    to: "/studios-history",
    color: "#FF6D00",
    icon: "mdi-file-video-outline",
    active: false,
    allows: null,
    blocks: null,
  },
  {
    title: "Xin nghỉ phép",
    to: "/off-submit-order",
    color: "#9E9D24",
    icon: "mdi-newspaper-plus",
    active: false,
    allows: null,
    blocks: null,
  },
  {
    title: "Lịch sử đơn từ",
    to: "/off-order",
    color: "#9E9D24",
    icon: "mdi-account-off",
    active: false,
    allows: null,
    blocks: null,
  },
];

export { navigators }