<template>
   <div>
      <v-data-table
         flat
         :headers="renderHeaders"
         :items="items"
         :loading="loading"
         loading-text="Loading... Please wait"
         no-data-text="Chưa có lịch sử"
         item-key="_id"
         :mobile-breakpoint="0"
         :items-per-page="pageSize"
         single-select
         dense
         class="elevation-0"
         :page.sync="pageIndex"
         @page-count="pageCount = $event"
         hide-default-footer
         >
         <template v-slot:item.propertyQR="{ item }">
           <b>{{item.propertyQR}}</b>
         </template>
         <template v-slot:item.propertyName="{ item }">
            <a :href="'/equipts-detail/' + item.property._id"
            style="font-size:15px;">
            {{ item.propertyName }}
            </a>
         </template>
         <template v-slot:item.status="{ item }">
            <span v-if="item.status=='LEND'" style="color:orange;font-weight:600;">Đang cho mượn</span>
            <span v-if="item.status=='RETURN'" style="color:green;font-weight:600;">Đã trả</span>
         </template>
         <template v-slot:item.lend_at="{ item }">
            {{ $moment(item.lend_at).format("DD/MM/YYYY, HH:mm ") }}
         </template>
         <template v-slot:item.return_at="{ item }">
            {{ item.return_at?$moment(item.return_at).format("DD/MM/YYYY, HH:mm"):''}}
         </template>
         <template v-slot:item.user_id="{ item }">
            {{ item.user?item.user.fullname:''}}
         </template>
         <template v-slot:item.program="{ item }">
            {{ item.program?item.program.name:''}}
         </template>
         <template v-slot:item.images="{ }">
            
         </template>
      </v-data-table>
      <div v-if="pageCount > 1" class="text-center pt-2">
         <v-pagination
            v-model="pageIndex"
            :length="pageCount"
         ></v-pagination>
      </div>
   </div>
</template>

<script>
export default {
   props: ['propertyID', 'queryStatus', 'showProperty'],
   computed: {
      renderHeaders: function () {
         if (this.showProperty) {
            return this.headers
         } else {
            return this.headers.filter(h => !h.value.includes('property'))
         }
      }
   },
   watch: {
      propertyID: async function () {
         await this.fetchHistory()
      }
   },
   data () {
      return {
         loading: false,
         items: [],
         users: [],
         pageIndex: 1,
         pageCount: 0,
         pageSize: 10,
         headers: [
            { text: "Mã QR", sortable: true, value: "propertyQR"},
            { text: "Thiết bị", sortable: true, value: "propertyName"},
            { text: "Trạng thái", sortable: true, value: "status"},
            { text: "Ngày mượn", sortable: true, value: "lend_at"},
            { text: "Ngày trả", sortable: true, value: "return_at"},
            { text: "Người mượn", sortable: false, value: "user_id"},
            // { text: "Chương trình", sortable: true, value: "program"},
            { text: "Ảnh chụp", sortable: true, value: "images"},
         ]
      }
   },
   methods: {
      async fetchHistory () {
         this.loading = true
         let query = "types=LEND-ASSET"
         if (this.propertyID) {
            query += `&&property_ids=${this.propertyID}`
         }
         if (this.queryStatus) {
            query += `&&statuses=${this.queryStatus}`
         }
         try {
            let res = await this.axios.get(this.$root.apiAssetMana + `/assetLends?${query}`)
            if (res.data.status == "OK") {
               this.items = res.data.content
               this.items.sort((a, b) => {
                  return new Date(b.lend_at).getTime() - new Date(a.lend_at).getTime()
               })
               this.items.forEach(item => {
                  item.user = this.users.find(u => u._id==item.user_id)
                  if (item.property) {
                     item.propertyName = item.property.name
                     item.propertyQR = item.property.qr_code
                  }
               })
               this.$forceUpdate()
            } else {
               alert('Lỗi! ' + res.data.message)
            }
         } catch (err) {
            alert('Lỗi! ' + err)
         }
         this.loading = false
      },
      async fetchUsers() {
         try {
         let res = await this.axios.get(
            this.$root.apiUser + "/users/list/" + this.$root.apiUserCustomId,
            {
               params: { showEnable: "true" },
            }
         )
         if (res.data.status == "OK") {
            this.users = res.data.content.items;
         }
         } catch (err) {
            console.log(err)
         }
      },
   },
   async created () {
      await this.fetchUsers()
      await this.fetchHistory()
   }
}
</script>

<style>

</style>