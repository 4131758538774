import Vue from "vue";
import store from "@/store";
import VueRouter from "vue-router";

Vue.use(VueRouter);

let routes = require("../mkdata/routes").routes;

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// let checkToken = (next) => {
//   console.log("checkToken");
//   let vm = router.app;
//   let path = vm.$root.apiUser + "/list/" + vm.$root.apiUserCustomId;
//   return vm.axios
//     .get(path, { headers: { "x-auth": store.state.token } })
//     .then((response) => {
//       if (
//         response.data.status != "OK" &&
//         response.data.status == "ERROR_VERIFY_TOKEN"
//       ) {
//         store.dispatch("logout").then(() => {
//           return next("/");
//         });
//       } else {
//         return Promise.resolve();
//       }
//     })
//     .catch((err) => {
//       console.log("Err:", err.message);
//       // return next("/");
//     });
// };
router.beforeEach((to, from, next) => {
  store.dispatch("fetchLocalStorage").then(async () => {
    if (to.fullPath == "/forget-password") {
      return next();
    }
    if (!store.state.userId || !store.state.token) {
      try {
        store.dispatch("logout").then(() => {
          if (to.fullPath !== "/login") {
            window.scrollTo(0, 0);
            return next("/login");
          } else {
            window.scrollTo(0, 0);
            return next();
          }
        });
      } catch (error) {
        console.log(error)
      }
    } else {
      window.scrollTo(0, 0);
      // if(to.fullPath == "/"){
      //   return next("/light")
      // }
      return next();
      // await checkToken(next).then(() => {
      //   window.scrollTo(0, 0);
      //   return next();
      // });
    }
  })
})

export default router;
