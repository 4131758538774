var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"flat":"","headers":_vm.renderHeaders,"items":_vm.items,"loading":_vm.loading,"loading-text":"Loading... Please wait","no-data-text":"Chưa có lịch sử","item-key":"_id","mobile-breakpoint":0,"items-per-page":_vm.pageSize,"single-select":"","dense":"","page":_vm.pageIndex,"hide-default-footer":""},on:{"update:page":function($event){_vm.pageIndex=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.propertyQR",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.propertyQR))])]}},{key:"item.propertyName",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"font-size":"15px"},attrs:{"href":'/equipts-detail/' + item.property._id}},[_vm._v(" "+_vm._s(item.propertyName)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status=='LEND')?_c('span',{staticStyle:{"color":"orange","font-weight":"600"}},[_vm._v("Đang cho mượn")]):_vm._e(),(item.status=='RETURN')?_c('span',{staticStyle:{"color":"green","font-weight":"600"}},[_vm._v("Đã trả")]):_vm._e()]}},{key:"item.lend_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.lend_at).format("DD/MM/YYYY, HH:mm "))+" ")]}},{key:"item.return_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.return_at?_vm.$moment(item.return_at).format("DD/MM/YYYY, HH:mm"):'')+" ")]}},{key:"item.user_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user?item.user.fullname:'')+" ")]}},{key:"item.program",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.program?item.program.name:'')+" ")]}},{key:"item.images",fn:function(ref){return undefined}}])}),(_vm.pageCount > 1)?_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.pageIndex),callback:function ($$v) {_vm.pageIndex=$$v},expression:"pageIndex"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }