<template lang="">
   <v-card v-if="user"
      flat style="border: 1px solid #DDDDDD;">
      <v-card-title 
         class="py-0 px-2"
         :style="{'background-color': hasAccess?'#C5E1A5':'#BBBBBB'}"
         style="font-size:13px;font-weight:600;background-color:#DDDDDD;">
         {{door.name}}
         <v-btn
            class="text-none ml-2 px-1"
            text
            color="primary" 
            small dark
            style="height:24px;min-height:20px;"
            @click="sendReadAllUserRequest">
            Check
         </v-btn>
         <v-spacer/>
         <template v-if="!loading && online">
            <v-btn v-if="hasAccess"
               class="text-none ml-1"
               color="red" 
               small dark
               style="height:24px;min-height:20px;"
               @click="sendDeletesUserFromDoor">
               Xóa
            </v-btn>
            <v-btn v-else
               class="text-none px-1 ml-1"
               color="primary" 
               small dark
               style="height:24px;min-height:20px;"
               @click="sendAddUserToDoor">
               Cấp phép
            </v-btn>
         </template>

         <v-btn
            :disabled="!hasAccess"
            color="primary"
            :loading="readOneUser"
            class="text-none px-1 ml-2"
            style="height:24px;"
            @click="sendReadOneUserRequest">
            Read
         </v-btn>
         
      </v-card-title>
      <v-card-text class="px-4 py-2"
         style="background-color:#F2F2F2;">
         <v-progress-linear
            v-if="loading"
            indeterminate
            color="cyan"
         ></v-progress-linear>

         <template v-else>
            <div v-if="hasAccess">
               <div style="color:green;font-weight:700;"> 
                  Được phép vào
               </div>
               <div class="mt-0" style="font-size:14px;color:#555555;font-weight:400;">
                  <div v-if="readOneUserErr"
                     style="line-height:2;color:red;">
                     Lỗi, hãy thử lại
                  </div>
                  <template v-if="localZKAccess">
                     <div class="mt-1" style="border-top:1px solid #DDDDDD;">
                        <div style="width:80px;display:inline-block;">
                           Mật khẩu:
                        </div>
                        <div style="width:60px;display:inline-block;font-weight:700;">
                           {{localZKAccess.password}}
                        </div>
                        <div style="width:80px;display:inline-block;">
                           Mã Thẻ:
                        </div>
                        <div style="width:60px;display:inline-block;font-weight:700;">
                           {{localZKAccess.card_no}}
                        </div>
                     </div>

                     <div
                        class="mt-2">
                        <div style="width:80px;display:inline-block;vertical-align:top;">
                        Vân tay:
                        </div>
                        <div style="width:200px;display:inline-block;vertical-align:top;max-width:100%;">
                           <div v-for="finger in localZKAccess.fingers"
                              :key="finger.index"
                              style="display:inline-block;">
                              <v-icon  v-if="finger.template.length>0"
                                 style="font-size:20px;"
                                 color="teal"
                                 >
                                 mdi-fingerprint
                              </v-icon>
                              <v-icon  v-else
                                 style="font-size:20px;"
                                 color="#AAAAAA44"
                                 >
                                 mdi-fingerprint
                              </v-icon>
                              <div class="text-center" style="font-size:10px;">
                                 {{finger.index+1}}
                              </div>
                           </div>
                        </div>
                        <div>
                           <div style="width:80px;display:inline-block;vertical-align:top;">
                              FaceId:
                           </div>
                           <div style="width:60px;display:inline-block;vertical-align:top;">
                              <div v-for="face in localZKAccess.faces"
                                 :key="face.index"
                                 style="display:inline-block;">
                                 <v-icon  v-if="face.template.length>0"
                                    style="font-size:20px;"
                                    color="teal"
                                    >
                                    mdi-guy-fawkes-mask
                                 </v-icon>
                                 <v-icon  v-else
                                    style="font-size:20px;"
                                    color="#AAAAAA44"
                                    >
                                    mdi-guy-fawkes-mask
                                 </v-icon>

                                 <div class="text-center" style="font-size:10px;">
                                    {{face.index + 1}}
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="mt-1" style="border-top: 1px solid #DDDDDD;">
                           <v-btn text color="primary"
                              class="text-none"
                              @click="saveTemplateAsPrimary">
                              Save thành bản chính
                           </v-btn>
                        </div>
                     </div>
                  </template>
               </div>
            </div>
            <div v-else 
               class="py-2"
               style="font-size:15px;font-weight:500;color:#AAAAAA;">
               Không được phép
            </div>
         </template>

      </v-card-text>
   </v-card>
</template>

<script>
export default {
   props: ['door', 'user', 'userId', 'zkAccess'],
   sockets: {
      'readOneUser-REPLY': function (payload) {
         if(payload && payload.askKey == this.socketAskKey){
            this.loading = false
            this.online = true
            this.hasAccess = true
            this.readOneUser = false
            this.readOneUserErr = false
            this.result = payload.result
            console.log('readOneUser-REPLY')
            console.log(this.result)
            /*
            name: "Lê Hoàng Vân"
            cardNumber: "3860820"
            id: "516"
            password: ""
            enabled: true
            role: 0
            faceTemplate: ""
            faceLength: 0
            fingers:[]
            */
            this.localZKAccess = {
               card_no: this.result.cardNumber,
               enabled: this.result.enabled,
               password: this.result.password,
               role: this.result.role,
               fingers: [],
               faces: [{index: 0, template: this.result.faceTemplate, fLength: this.result.faceLength }]
            }

            this.result.fingers.forEach((finger, index) => {
               this.localZKAccess.fingers.push({
                  index: index,
                  template: finger
               })
            })

            this.$forceUpdate()
         }
         // console.log("Call spend time: " 
         //    + Math.round(new Date().getTime() - this.sendTime.getTime()))
      },
      'readAllUserLite-REPLY': function(payload){
         if(payload && payload.askKey == this.socketAskKey){
            this.online = true
            console.log('readAllUserLite-REPLY ' + this.socketAskKey)
            // console.log(payload)
            this.loading = false
            // payload.result.users.forEach(u => console.log(u))
            /*
               name: "Lê Như Huỳnh"
               cardNumber: null
               id: "T65"
               password: ""
               enabled: true
               role: 0
            */

            let userId = this.userId.replace('NV', '')
            if(!userId.includes('T')) {
               userId = ""+ Number(userId)
            }

            this.localZKAccess = null

            let matchUser = payload.result.users.find(u => u.id == userId)

            if(matchUser){
               this.hasAccess = true
               // this.localZKAccess = {
               //    card_no: matchUser.cardNumber,
               //    enabled: true,
               //    password: matchUser.password,
               //    role: matchUser.role,
               //    fingers: null,
               //    faces: null
               // }
            }
            this.$forceUpdate()
         }
      },
      'deleteUser-REPLY': function (payload) {
         this.online = true
         this.loading = false
         if(payload && payload.askKey == this.socketAskKey){
            this.result = payload.result
            console.log('deleteUser-REPLY')
            console.log(this.result)
            alert('Delete ' + this.result.bResult?'done':'fail')
            this.sendReadAllUserRequest()

            this.$forceUpdate()
         }
         console.log("Call spend time: " 
            + Math.round(new Date().getTime() - this.sendTime.getTime()))
      },
      'setUserInfo-REPLY': function (payload) {
         this.online = true
         this.loading = false
         if(payload && payload.askKey == this.socketAskKey){
            let result = payload.result
            console.log('setUserInfo-REPLY')
            console.log(result.bResult)
            
            if(result.bResult){
               alert('Set user done!')
               this.sendReadAllUserRequest()
            } else {
               alert('Set user fail!')
            }
            this.$forceUpdate()
         }
      }
   },
   data () {
      return {
         localZKAccess: null,
         socketAskKey: new Date().getTime(),
         loading: false,
         online: false,
         hasAccess: false,
         readOneUser: false,
         readOneUserErr: false,
         lastCallReadOne: null
      }
   },
   methods: {
      sendReadAllUserRequest(){
         if(!this.door || !this.door.ip) return
         this.loading = true
         this.sendTime = new Date()
         console.log('readAllUserLite ' + this.socketAskKey)
         this.$socket.emit('sendMagicCommand', 
         {
            command: "readAllUserLite",
            askKey: this.socketAskKey,
            devCode: "MCVZK-" + this.door.ip,
            paras: {}
         })
      },
      sendReadOneUserRequest(){
         if(!this.door || !this.door.ip || !this.userId) return

         if(this.lastCallReadOne && (new Date().getTime() - this.lastCallReadOne.getTime())<1000){
            return
         }

         this.lastCallReadOne = new Date()

         let userId = this.userId.replace('NV', '')
         if(!userId.includes('T')) {
            userId = ""+ Number(userId)
         }
         this.sendTime = new Date()
         console.log('readOneUser ' + this.socketAskKey)
         this.loading = true
         this.readOneUser = true
         this.readOneUserErr = false
         let self = this
         setTimeout(function(){
            if(self.readOneUser){
               self.readOneUser = false
               self.readOneUserErr = true
               self.loading = false
            }
         }, 15000)
         this.localZKAccess = null
         console.log("sendMagicCommand readOneUser " + "MCVZK-" + this.door.ip)
         this.$socket.emit('sendMagicCommand', 
         {
            command: "readOneUser",
            askKey: this.socketAskKey,
            devCode: "MCVZK-" + this.door.ip,
            paras: {
               userId: userId
            }
         })
      },
      sendDeletesUserFromDoor(){
         if(!this.door || !this.door.ip || !this.userId) return
         let userId = this.userId.replace('NV', '')
         if(!userId.includes('T')) {
            userId = ""+ Number(userId)
         }

         this.loading = true
         this.hasAccess = false
         let self = this
         setTimeout(function(){
            self.loading = false
         },8000)
         this.sendTime = new Date()
         this.$socket.emit('sendMagicCommand', 
         {
            command: "deleteUser",
            askKey: this.socketAskKey,
            devCode: "MCVZK-" + this.door.ip,
            paras: {
               userId: userId
            }
         })
      },
      sendAddUserToDoor(){
         if(!this.door || !this.door.ip || !this.userId) return
         let userId = this.userId.replace('NV', '')
         if(!userId.includes('T')) {
            userId = ""+ Number(userId)
         }

         let user = this.user

         let userCode = user.user_code.replace("NV", "")
         if(!userCode.includes('T')){
            userCode = ""+Number(userCode)
         }

         let paras = {
            userId: userCode,
            name: user.fullname,
            password: '',
            card_no: '',
            face: '',
            role: 0,
            faceLength: 0,
            fingers: ['','','','','','','','','','']
         }

         if(user.zk_access){
            paras.password = user.zk_access.password
            paras.card_no = user.zk_access.card_no
            if(user.zk_access.role && user.zk_access.role.length>0) {
               paras.role = Number(user.zk_access.role)
            }
            if(user.zk_access.faces && user.zk_access.faces.length>0){
               paras.face = user.zk_access.faces[0].template
               paras.faceLength = user.zk_access.faces[0].fLength
            }

            if(user.zk_access.fingers && user.zk_access.fingers.length>0){
               user.zk_access.fingers.forEach((finger) => {
               // console.log(finger)
               paras.fingers[finger.index] = finger.template
               })
            }

         }

         console.log(paras)
         
         this.loading = true
         let self = this
         setTimeout(function(){
            self.loading = false
         },8000)
         this.sendTime = new Date()
         this.$socket.emit('sendMagicCommand', 
         {
            command: "setUserInfo",
            askKey: this.socketAskKey,
            devCode: "MCVZK-" + this.door.ip,
            paras: paras
         })
      },
      saveTemplateAsPrimary(){
         if(!this.localZKAccess) return
         console.log(this.localZKAccess)
         let devUser = this.localZKAccess
         let zk_access = {
               // user_id: devUser.id,
               password: devUser.password||"",
               card_no: devUser.card_no||"",
               role: devUser.role||0,
               enable: true,
               fingers: devUser.fingers,
               faces: devUser.faces
         }

         console.log(zk_access)

         let self = this
         
         this.axios.put('https://mcvusr.duocdo.com/v1/users/update/' + this.user._id,
         { zk_access: zk_access }
         ).then(res => {
               if(res.data.status=="OK"){
                  alert("Done")
                  location.reload()
               } else {
                  alert("Error " + res.data.message)
               }
               console.log("Save " + self.user._id + " got " + res.data.status)
               console.log(res.data.message)
         })
      }
   },
   mounted() {
      // this.sendReadOneUserRequest()
      let self = this
      for(let i=0; i<5; i++){
         setTimeout(function(){
            if(!self.online){
               self.sendReadAllUserRequest()
            }
         }, 5000*i)
      }
   },
   created: function() {
      this.$parent.$on('requestChildReadUserFull', this.sendReadOneUserRequest);
   }
}
</script>