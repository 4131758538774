<template>
  <v-card v-if="show" style="border-radius:0;background-color:#F2F2F2;">
    <v-card-title class="px-4 py-1"
      style="color:#222222;border-bottom:1px solid #222222;">
      Thêm sự kiện
      <v-spacer/>
      <v-btn 
        text
        style="height:30px;color:#444444;"
        class="text-none"
        @click.native="$emit('close-dialog')">
        Hủy
      </v-btn>
      <v-btn
        text
        style="height:30px;color:#AD1457;"
        class="text-none"
        @click.native="saveEvent">
        Lưu
      </v-btn>
    </v-card-title>
    <v-card-text style="min-height:200px;">
      <div class="mt-2">
        Mô tả:
      </div>
      <div class="description">
        <v-textarea
          flat
          dense
          v-model="event.note"
          :rules="[]"
          color="#AD1457"
          :clearable = "$vuetify.breakpoint.smAndDown"
          outlined
          filled auto-grow
          hide-details
        ></v-textarea>
      </div>

      <!-- image -->
        <div class="py-1 mt-2">
          <!-- upload image -->
          <div>
            <input
              type="file"
              style="display: none"
              ref="imageInputForDamage"
              accept="*/*"
              @change="handleFileUpload"
            />

            <v-btn
              @click.stop="startPickImage"
              class="ml-2"
              color="teal"
              outlined
              style="min-width:20px;width:46px;"
            >
              <v-icon>
                add_photo_alternate
              </v-icon>
            </v-btn>
          </div>
          <!-- list of image -->
          <div class="py-2">
            <template v-for="(img, imgIdx) in event.images">
              <div
                class="pa-1 mr-2 mb-2"
                :key="'img' + imgIdx"
                style="vertical-align: top;position:relative;display: inline-block;min-height: 80px;max-height: 80px;min-width: 130px;max-width: 130px;border:1px solid #DDDDDD;"
              >
                <div
                  @click.stop="removeImage(imgIdx)"
                  style="position:absolute;top:-10px;right:-5px;z-index:2;cursor:pointer;"
                >
                  <v-icon color="red" style="font-size:20px;"
                    >mdi-close-circle-outline</v-icon
                  >
                </div>

                <div
                  style="width:100%;height:70px;z-index:1; overflow-y:hidden;cursor:pointer;"
                  @click.stop="showImageFullScreen(img)"
                >
                  <img
                    :src="img"
                    style="object-fit:cover;width:100%;height:100%;"
                  />
                </div>
              </div>
            </template>
          </div>
        </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    props:["show"],
    computed: {
      ...mapGetters([
        "userId",
      ]),
    },
    data () {
      return {
        event: {
          userIds: [],
          notedBy: '',
          note: '',
          images: []
        }
      }
    },
    methods: {
      showImageFullScreen(link) {
        console.log(link)
        // this.showFullImage = true;
        // this.imgFullScreen = link;
      },
      removeImage(p) {
        this.event.images.splice(p, 1);
      },
      startPickImage() {
        this.$refs.imageInputForDamage.click();
      },
      handleFileUpload: function() {
        var file = this.$refs.imageInputForDamage.files[0];
        if (file !== undefined) {
          var self = this;
          self.result = "";
          let formData = new FormData();
          formData.append("file", file);
          self.axios
            .post("https://file.ponotek.com/uploads", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(function(res) {
              console.log("Upload image:", res.data);
              self.event.images.push(res.data.content);
            })
            .catch(function(err) {
              console.log("Err upload:", err);
            });
        }
      },
      saveEvent () {
        if(this.event.note.length<=0){
          alert('Vui lòng điền nội dung ghi chú!')
          return
        }
        this.event.date = new Date()
        this.event.notedBy = this.userId

        this.$emit('add-event', this.event)
      }
    }
}
</script>